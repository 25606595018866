.container-members {
  max-height: 800px;
  max-width: 1280px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 20px;
  transition: transform 0.4s;
}

.container-members:hover {
  transform: scale(1.1);
}

.border-members {
  height: 378px;
  width: 300px;
  border-radius: 10px;
  transition: border 1s;
  border: 1px solid white;
}

.card-members {
  height: 380px;
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: center center no-repeat,
    radial-gradient(circle, #441a2a, #37182b, #2a162a, #1d1326, #120f20);
  background-size: 300px;
  transition: all 0.8s;
}

.card-members:hover {
  background: left center no-repeat,
    radial-gradient(circle, #441a2a, #37182b, #2a162a, #1d1326, #120f20);
  background-size: 600px;
}

.img-members {
  width: inherit;
  height: auto;
  position: absolute;
  z-index: 0;
  transition: opacity 1s;
}

.card-members:hover .border-members h2,
.card-members:hover .fa,
.card-members:hover .border-members h3 {
  opacity: 1;
  z-index: 10;
}

.card-members:hover img {
  opacity: 0.5;
  z-index: 0;
}

.border-members h2 {
  position: relative;
  color: white;
  margin: 20px;
  opacity: 0;
  transition: opacity 1s;
  font-family: 'Roboto Mono', monospace;
}

.border-members h3 {
  position: relative;
  color: white;
  margin: 20px;
  opacity: 0;
  transition: opacity 1s;
  font-family: 'Roboto Mono', monospace;
}

.fa-members {
  opacity: 0;
  transition: opacity 1s;
}

.card-members a:hover {
  cursor: pointer;
}

.icons-members {
  position: absolute;
  fill: white;
  color: white;
  height: 130px;
  top: 226px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 1000;
}

.icons-members a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

:root {
  --shadow-color: #ff9e9e;
  --shadow-color-light: white;
}

.main-container-members {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.heading-members {
  text-align: center;
  margin: 1rem;
  color: white;
  font-size: 5rem;
  text-shadow: 2px 2px 4px #000000;
  font-weight: 700;
  font-family: 'Roboto Mono', monospace;
}

.sec-heading-members {
  text-align: center;
  margin: 0.8rem;
  margin-bottom: 0%;
  color: white;
  font-size: 3rem;
  text-shadow: 2px 2px 4px #000000;
  font-weight: 700;
  position: relative;
}

.por-groups {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.navbar-members {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.nav-buttons-members {
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 4rem;
  background: radial-gradient(
    circle,
    #441a2a,
    #37182b,
    #2a162a,
    #1d1326,
    #120f20
  );
  border-radius: 1rem;
  color: white;
  font-weight: 700;
  font-size: 2rem;
  box-shadow: 0 70px 63px -60px #000000;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  width: fit-content;
  text-align: center;
}

.nav-buttons-members:hover {
  cursor: pointer;
  box-shadow: 0 0 7px #441a2a, 0 0 10px #37182b, 0 0 42px #120f20,
    0 0 82px #120f20;

  text-shadow: -1px -1px 1px var(--shadow-color-light),
    -1px 1px 1px var(--shadow-color-light),
    1px -1px 1px var(--shadow-color-light);
}

@media (max-width: 1100px) {
  .heading-members {
    font-size: 4rem;
  }

  .sec-heading-members {
    font-size: 2rem;
  }

  .nav-buttons-members {
    font-size: 1.4rem;
  }
}
