.navbar_M {
  background: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar_M_container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

#navbar_M_logo {
  background-color: #ff8177;
  background-image: linear-gradient(to top, #6532dc 0%, #2629dc 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.navbar-M-logo{
  width: 300px;
}

.fa-gem {
  margin-right: 0.5rem;
}

.navbar_M_menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.navbar_M-item {
  height: 60px;
}

.navbar_M-links {
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
}
#rogleg{
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-reg{
  text-decoration: none;
  color: #fff;
  padding: 0 1rem;
  height: 100%;
}

.navbar_M-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
}

.navbar_M-links:hover {
  color: #8b2cea;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1100px) {
  .navbar_M_container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0;
  }

  .navbar_M_menu {
    display: grid;
    grid-template-columns: auto;
    margin: 0;
    width: 100%;
    position: absolute;
    top: -1000px;
    opacity: 0;
    transition: all 0.5s ease;
    background: #131313;
    height: auto;
    z-index: -1;
  }

  .navbar_M_menu.active {
    background: #131313;
    top: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 99;
    height: auto;
    font-size: 1.6rem;
  }

  #navbar_M_logo {
    padding-left: 25px;
  }

  .navbar_M_toggle .bar {
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background: #fff;
  }

  .navbar_M-item {
    width: 100%;
  }

  .navbar_M-links {
    text-align: center;
    padding: 1rem 0 0 0;
    width: 100vw;
    display: table;
    height: 3rem;
  }

  #mobile-menu {
    position: absolute;
    top: 20%;
    right: 5%;
    transform: translate(5%, 20%);
  }

  .navbar_M-btn {
    padding-bottom: 2rem;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80px;
    margin: 0;
  }

  .navbar_M_toggle {
    display: block;
    cursor: pointer;
  }

  .navbar_M_toggle .bar {
    display: block;
  }

  #mobile-menu.is-active .bar:nth-child(2) {
    opacity: 0;
  }

  #mobile-menu.is-active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  #mobile-menu.is-active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
