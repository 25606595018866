@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Roboto:wght@300;400;500;700&display=swap");

.signup-page {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.signup-page .signup {
  position: relative;
  z-index: 20;
  overflow: hidden;
}
.signup-page .cloud2 {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.9;
  display: none;
}
@media (max-width: 1100px) {
  .signup-page .cloud2 {
    width: 160px;
    display: none;
  }
}
.signup-page input::-webkit-outer-spin-button,
.signup-page input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.signup-page input[type="number"] {
  -moz-appearance: textfield;
}
.signup-page .back {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  /* background-color: #41287c;
	 */
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #703f90;
  border: none;
  outline: none;
  cursor: pointer;
}
.signup-page .back:hover {
  background-color: #9c2388;
  color: white;
}
.signup-page .back i {
  position: relative;
  left: -1px;
}
.signup-page .signup-inner {
  margin: 60px auto 50px;
  /* height: 600px;
	 */
  width: 80%;
  /* flex-wrap: wrap;
	 */
  /* margin-top: 40px;
	 */
  /* margin-bottom: 50px;
	 */
  /* padding-bottom: 20px;
	 */
  /* box-shadow: 0 10px 20px rgb(92, 60, 133);
	 */
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 1100px) {
  .signup-page .signup-inner {
    width: 100%;
    /* margin: 20px 10px;
		 */
    flex-direction: column-reverse;
    /* flex-wrap: wrap;
		 */
    /* padding: 10px;
		 */
    align-items: stretch;
    justify-content: center;
    margin: 0;
    padding: 80px 0 50px;
    height: auto;
    box-shadow: 0 10px 20px #5c3c85;
  }
}
.signup-page .signup-detail {
  background-color: white;
  /* width: 35%;
	 */
  padding-bottom: 80px;
  /* border-top-left-radius: 20px;
	 */
  /* height: 100%;
	 */
  position: relative;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail {
    width: auto;
    /* margin-bottom: 80px;
		 */
    margin: 0 10px 80px;
    flex: 1;
  }
}
.signup-page .signup-detail img {
  position: absolute;
  width: 25%;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail img {
    width: 50%;
  }
}
.signup-page .signup-detail .cloud3 {
  width: 20%;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.9;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail .cloud3 {
    width: 35%;
  }
}
.signup-page .signup-detail .signup {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap;
	 */
  /* align-items: center;
	 */
  /* justify-content: start;
	 */
  padding: 0 12%;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail .signup {
    padding: 20px 15px 0;
  }
}
.signup-page .signup-detail .signup-info {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap;
	 */
  color: #472b7f;
  margin-top: 60px;
  /* align-items: center;
	 */
}
@media (max-width: 1100px) {
  .signup-page .signup-detail .signup-info {
    margin-top: 20px;
  }
}
.signup-page .signup-detail h1 {
  color: #472b7f;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  margin-top: 50px;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail h1 {
    margin-top: 90px;
  }
}
.signup-page .signup-detail h2 {
  color: #472b7f;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.signup-page .signup-detail form {
  text-align: center;
}
.signup-page .signup-detail .form-section {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail .form-section {
    flex-direction: column;
  }
}
.signup-page .signup-detail .inner-section {
  flex: 1;
  padding: 0 20px;
  text-align: left;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail .inner-section {
    padding: 0;
  }
}
.signup-page .signup-detail .field {
  margin: 30px 10px;
}
.signup-page .signup-detail .label {
  display: flex;
  align-items: center;
}
.signup-page .signup-detail .label svg {
  margin-right: 5px;
}
.signup-page .signup-detail label {
  font-size: 20px;
}
.signup-page .signup-detail .remark {
  color: #ff002b;
  font-weight: 400;
  font-size: 16px;
}
.signup-page .signup-detail input {
  outline: none;
  border: none;
  color: #251447;
  font-size: 16px;
  background: none;
  width: 100%;
  margin-left: 5px;
  border-bottom: 1.5px solid #bdbcc2;
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  /* font-weight: 400;
	 */
}
@media (max-width: 1100px) {
  .signup-page .signup-detail input {
    width: 100%;
    margin: 10px 0;
  }
}
.signup-page .signup-detail input::placeholder {
  color: #5f5b68;
  font-weight: 300;
  font-size: 16px;
}
.signup-page .signup-detail input:focus {
  border-bottom: 1.9px solid #472b7f;
}
.signup-page .signup-detail button {
  /* margin-top: 40px;
	 */
  background-color: transparent;
  background: linear-gradient(90deg, #704191, #4c2f81);
  border: none;
  outline: none;
  color: white;
  font-size: 20px;
  padding: 15px 0;
  border-radius: 7px;
  font-weight: 500;
  margin: 30px 0 10px;
  width: 200px;
  cursor: pointer;
}
.signup-page .signup-detail button:hover {
  background: linear-gradient(90deg, #9e1092, #8817b4);
}
.signup-page .signup-detail .login {
  display: flex;
  font-weight: 500;
  margin-top: 20px;
  justify-content: center;
}
@media (max-width: 1100px) {
  .signup-page .signup-detail .login {
    text-align: center;
  }
}
.signup-page .signup-detail .login .newhere {
  color: #9b8abd;
  margin-right: 5px;
}
.signup-page .signup-detail .login a {
  text-decoration: none;
}
.signup-page .signup-img {
  /* width: 65%;
	 */
  position: absolute;
  top: -30px;
  right: -30px;
  height: 100%;
  width: 100%;
  /* height: 100%;
	 */
  /* align-self: stretch;
	 display: flex;
	 */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.signup-page .signup-img img {
  opacity: 0.5;
  height: 50%;
  width: auto;
}
@media (max-width: 1100px) {
  .signup-page .signup-img img {
    width: 100%;
    opacity: 0.2;
  }
}
@media (max-width: 1100px) {
  .signup-page .signup-img {
    right: -80px;
  }
}
