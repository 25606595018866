@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@600&family=Signika+Negative:wght@700&family=Uchen&display=swap");

.guest-page-container {
  background-color: rgba(32, 33, 32, 0.5);
  border-radius: 1rem;
  margin: 4rem 10%;
  padding-top: 1.25rem;
}

.guest-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 6rem;
  position: relative;
  z-index: 10;
}

#guest-img {
  width: 15rem;
  height: 15rem;
  border-right: #eee 1px solid;
}

#merch-img1 {
  width: 25vw;
  height: auto;
  border: black 3px solid;
  border-radius: 30px;
  margin-right: 10%;
}
#merch-img2 {
  width: 25vw;
  height: auto;
  border: black 3px solid;
  border-radius: 30px;
  margin-left: 10%;
}

#merch{
  width: 100%;
  margin-top: 10vh;
  display: flex;
  align-content: center;
  justify-content: center;
}

#guest-page-heading {
  font-size: 4rem;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  text-align: center;
}

.content-guest {
  text-align: center;
  margin: 20px 0px;
}

#guest-heading {
  font-size: 3rem;
  font-family: sans-serif;
  color: #801fe1;
  margin-top: 0;
}
#shirt-heading {
  font-size: 3rem;
  font-family: sans-serif;
  color: white;
  margin-top: 0;
}

#guest-description {
  font-size: 1.1rem;
  font-family: serif;
  margin: 40px 1rem;
  line-height: 25px;
}
#shirt-description {
  font-size: 1.1rem;
  font-family: sans-serif;
  margin: 40px 1rem;
  line-height: 25px;
}

#guest-button {
  display: flex;
  justify-content: space-around;
  transition: transform 0.2s;
}

#guest-button:hover {
  transform: scale(1.1);
}

#merch-button {
  display: flex;
  justify-content: space-around;
  margin-top: 10vh;
}

.glow-on-hover-guests {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: rgb(50, 47, 47);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin: 5px;
}

.glow-on-hover-guests:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-guests 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover-guests:active {
  color: #000;
}

.glow-on-hover-guests:active:after {
  background: transparent;
}

.glow-on-hover-guests:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing-guests {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}
#coming_soon {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  height: 50vh;
  vertical-align: middle;
  padding-top: 10%;
  font-size: 40px;
}

@media (max-width: 1100px) {
  #guest-page-heading {
    font-size: 2rem;
    text-align: center;
  }

  .guest-container {
    flex-direction: column;
    margin: 1.3rem 1rem;
    align-items: center;
    justify-content: center;
  }

  #guest-description {
    font-size: 0.8rem;
  }

  #guest-img {
    width: 10rem;
    height: 10rem;
    border-right: none;
    border-bottom: #eee 1px solid;
    margin: 20px 0px;
  }
  #merch-img1 {
    width: 55vw;
    height: auto;
    border: black 3px solid;
    border-radius: 30px;
    margin-right: 10%;
    margin-left: 10%;
  }
  #merch-img2 {
    width: 55vw;
    height: auto;
    border: black 3px solid;
    border-radius: 30px;
    margin-left: 10%;
    
    margin-right: 10%;
  }
  #merch {
    flex-direction: column;
    align-items: center;
  }

  #guest-heading {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }
  #shirt-heading {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }

  #coming_soon {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    height: 50vh;
    vertical-align: middle;
    padding-top: 30%;
    font-size: 20px;
  }

  .glow-on-hover-guests {
    margin-bottom: 0.5rem;
  }
}
