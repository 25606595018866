@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
.boom_text {
  font-family: "Open Sans", cursive;
  position: absolute;
  top: 60vh;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-size: 2vw;
  color: rgb(255, 255, 255);
  text-align: center;
  z-index: 1000;
}
.boom_subtext {
  font-size: 1vw;
}

.title_logo_div {
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: center;
  top: 12vh;
}

.title_logo {
  width: 28vw;
  height: auto;
}

@media (max-width: 800px) {
  .title_logo {
    width: 50vw;
    height: auto;
  }
  .boom_text {
    top: 57vh;
    width: 90%;
    font-size: 3vh;
  }
  .boom_subtext {
    padding-top: 21px;
    padding-bottom: 20px;
    font-size: 2vh;
  }
}
