@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');
#container123 {
  padding: 0px;
  width: 100vw;
  height: 200vh;
  background-color: transparent;
  display: flex;
  transform: translateY(-50%);
}

#sun {
  --size: calc(2 * 150px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  background-image: url("../images/Sun.png");
  background-size: cover;
  /* background-color: rgb(255, 174, 0); */
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease-in;
}

#p1_p {
  --size: calc(2 * 380px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px gray;
  opacity: 0.29;
}

#p2_p {
  --size: calc(2 * 460px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px gray;
  opacity: 0.31;
}

#p3_p {
  --size: calc(1.9 * 600px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px grey;
  opacity: 0.33;
}

#p4_p {
  --size: calc(2 * 300px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px gray;
  opacity: 0.27;
}

#p5_p {
  --size: calc(2 * 230px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px gray;
  opacity: 0.25;
}

#p6_p {
  --size: calc(2 * 690px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px gray;
  opacity: 0.35;
}

#p7_p {
  --size: calc(2 * 800px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px gray;
  opacity: 0.35;
}

#p8_p {
  --size: calc(2 * 900px);
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  border-radius: 50%;
  border: solid 1px gray;
  opacity: 0.25;
}

#p1,
#p2,
#p3,
#p1_1,
#p2_2,
#p3_3,
#p4,
#p4_4,
#p5,
#p5_5,
#p6,
#p6_6,
#p7,
#p7_7,
#p8,
#p8_8 {
  cursor: pointer;
}

.planets .planet-details {
  width: auto;
  position: absolute;
  top: calc(155% - calc(var(--size) / 2));
  left: calc(60% - calc(var(--size) / 2));
  background-color: rbga(0, 0, 0, 0);
  /* border: solid 2px red;
  border-radius: 10px; */
  color: white;
  text-align: center;
  visibility: visible;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 20;
}

@media (max-width: 1100px) {
  .planets .planet-details {
    left: 120px;
    top: 35%;
    width: 200px;
    text-align: left;
  }
}

#p1,
#p1_1 {
  --size: 75px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  background-image: url("../images/Earth2.png");
  background-size: cover;
  border-radius: 50%;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  animation: orbit_1 calc(2.3s) linear 1 forwards;
  z-index: 1;
  box-shadow: 5px -6px 20px -9px #fff, 5px -6px 20px -9px rgb(8 57 216);
}

#p2,
#p2_2 {
  --size: 75px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  background-image: url("../images/mars2.png");
  background-size: cover;
  border-radius: 50%;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  animation: orbit_2 calc(2.3s) linear 1 forwards;
  z-index: 1;
  box-shadow: -7px -7px 30px -18px #fff, -7px -7px 30px -18px rgb(238, 71, 10);
}

#p3,
#p3_3 {
  --size: 80px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  background-image: url("../images/Jupiter2.png");
  background-size: cover;
  border-radius: 50%;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  animation: orbit_3 calc(2.7s) linear 1 forwards;
  z-index: 1;
  box-shadow: 5px -6px 20px -9px #fff, 5px -6px 20px -9px rgb(97, 44, 0);
}

#p4,
#p4_4 {
  --size: 75px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  background-image: url("../images/venus2.png");
  background-size: cover;
  border-radius: 50%;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  animation: orbit_4 calc(2s) linear 1 forwards;
  z-index: 1;
  box-shadow: -7px -7px 30px -18px #fff, -7px -7px 30px -12px rgb(250, 184, 2);
}

#p5,
#p5_5 {
  --size: 65px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  background-image: url("../images/mercury2.png");
  background-size: cover;
  border-radius: 50%;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  animation: orbit_5 calc(2s) linear 1 forwards;
  z-index: 1;
  box-shadow: 9px -5px 20px -9px #fff, 9px -5px 20px -9px rgb(156 155 152);
}

#p6,
#p6_6 {
  --size: 85px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  background-image: url("../images/uranus.png");
  background-size: cover;
  border-radius: 50%;
  top: calc(50% - calc(var(--size) / 2));
  left: calc(50% - calc(var(--size) / 2));
  animation: orbit_6 calc(2.7s) linear 1 forwards;
  z-index: 1;
  box-shadow: -7px -7px 30px -18px #fff, -7px -7px 30px -14px #0ff;
}

.planets {
  transition: all 0.5s ease;
}

.planets:hover {
  transform: scale(1.5);
}

.planets:hover .planet-details {
  visibility: visible;
  opacity: 1;
  transform: scale(1.1);
}

#sun:hover {
  transform: scale(1.1);
}

.bottom_text_container {
  position: absolute;
  bottom: -9%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.bottom_text_container.active {
  bottom: 1%;
  transition: 1s ease-in;
}

.bottom_text {
  font-family: "Josefin Sans", cursive;
  font-size: 2rem;
}

.sponsor_bottom_logo {
  position: absolute;
  bottom: 27%;
  left: -25%;
  width: 24vw;
  height: auto;
}

.sponsor_bottom_logo.active {
  left: 38.5%;
  transition: 1.3s ease-in;
}

.nssc_logo {
  position: absolute;
  bottom: 8%;
  right: -20%;
  width: 8.5vw;
  height: auto;
}

.nssc_logo.active {
  right: 46.5%;
  transition: 1.3s ease-in;
}

.background-galaxy {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: -1;
}

@keyframes orbit_1 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 190px)) rotate(-125deg);
  }

  to {
    transform: rotate(125deg) translateX(calc(2 * 190px)) rotate(-125deg);
  }
}

@keyframes orbit_1_1 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 190px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(2 * 190px)) rotate(-180deg);
  }
}

@keyframes orbit_2 {
  from {
    transform: rotate(0deg) translateX(calc(2 * 230px)) rotate(0deg);
  }

  to {
    transform: rotate(55deg) translateX(calc(2 * 230px)) rotate(-55deg);
  }
}

@keyframes orbit_2_2 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 230px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(2 * 230px)) rotate(-180deg);
  }
}

@keyframes orbit_3 {
  from {
    transform: rotate(180deg) translateX(calc(1.9 * 300px)) rotate(-125deg);
  }

  to {
    transform: rotate(125deg) translateX(calc(1.9 * 300px)) rotate(-125deg);
  }
}

@keyframes orbit_3_3 {
  from {
    transform: rotate(180deg) translateX(calc(1.9 * 300px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(1.9 * 300px)) rotate(-180deg);
  }
}

@keyframes orbit_4 {
  from {
    transform: rotate(0deg) translateX(calc(2 * 150px)) rotate(0deg);
  }

  to {
    transform: rotate(55deg) translateX(calc(2 * 150px)) rotate(-55deg);
  }
}

@keyframes orbit_4_4 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 150px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(2 * 150px)) rotate(-180deg);
  }
}

@keyframes orbit_5 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 115px)) rotate(-125deg);
  }

  to {
    transform: rotate(125deg) translateX(calc(2 * 115px)) rotate(-125deg);
  }
}

@keyframes orbit_5_5 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 115px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(2 * 115px)) rotate(-180deg);
  }
}

@keyframes orbit_6 {
  from {
    transform: rotate(0deg) translateX(calc(2 * 345px)) rotate(0deg);
  }

  to {
    transform: rotate(55deg) translateX(calc(2 * 345px)) rotate(-55deg);
  }
}

@keyframes orbit_6_6 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 345px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(2 * 345px)) rotate(-180deg);
  }
}

@keyframes orbit_7 {
  from {
    transform: rotate(0deg) translateX(calc(2 * 400px)) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(calc(2 * 400px)) rotate(-360deg);
  }
}

@keyframes orbit_7_7 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 400px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(2 * 400px)) rotate(-180deg);
  }
}

@keyframes orbit_8 {
  from {
    transform: rotate(0deg) translateX(calc(2 * 450px)) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(calc(2 * 450px)) rotate(-360deg);
  }
}

@keyframes orbit_8_8 {
  from {
    transform: rotate(180deg) translateX(calc(2 * 450px)) rotate(180deg);
  }

  to {
    transform: rotate(540deg) translateX(calc(2 * 450px)) rotate(-180deg);
  }
}

@media screen and (max-width: 800px) {
  #container123 {
    background-color: transparent;
  }

  .bottom_text_container {
    display: none;
  }
}
