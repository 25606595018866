@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@600&family=Signika+Negative:wght@700&family=Uchen&display=swap");

.event-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5rem;
}

#event-img {
  width: 25rem;
  height: 25rem;
}

#event-page-heading {
  font-size: 4rem;
  font-family: "Signika Negative", sans-serif;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.content-event {
  text-align: center;
}

#event-heading {
  font-size: 3rem;
  font-family: "Chakra Petch", sans-serif;
  color: #801fe1;
  margin-top: 0;
}

#event-description {
  font-size: 1.25rem;
  font-family: "Uchen", serif;
  margin: 40px 0;
  width: 100%;
  line-height: 25px;
}

#event-button {
  display: flex;
  justify-content: space-around;
}

.events-glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: rgb(50, 47, 47);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin: 5px;
}

.events-glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: event-glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.events-glow-on-hover:active {
  color: #000;
}

.events-glow-on-hover:active:after {
  background: transparent;
}

.events-glow-on-hover:hover:before {
  opacity: 1;
}

.events-glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes event-glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.navigation-dots div div div {
  background-color: white !important;
}

@media (max-width: 1100px) {
  .event-container {
    flex-direction: column;
    margin: 0 8rem;
  }

  #event-heading {
    font-size: 2rem;
  }

  #event-page-heading {
    font-size: 2rem;
  }

  #event-img {
    width: 12rem;
    height: 12rem;
  }

  #event-description {
    font-size: 1.3rem;
    width: 100%;
  }

  .events-glow-on-hover {
    width: 170px;
    box-shadow: 0 0 0 2px;
    background-color: black;
  }
}
