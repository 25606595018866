@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");

.solar_systm {
  transform-style: preserve-3d;
  pointer-events: none;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

/* #kgp_logo{
  position: fixed;
  bottom: 1vh;
  right: 2vw;
  width: 12vw;
  height: auto;
} */

/* .planet-list{
  height: 90vh;
} */

/* @media only screen and (max-height: 1100px){
  .planet
} */

body .solar_systm .planet,
body input[type="radio"][name="planet"]::after,
body .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}

body .solar_systm .planet.neptune .trajectory.ner,
body .solar_systm .planet.neptune .trajectory.pro,
body .solar_systm .planet.neptune .trajectory.tri,
body .solar_systm .planet.uranus .trajectory.umb,
body .solar_systm .planet.uranus .trajectory.ari,
body .solar_systm .planet.uranus .trajectory.mir,
body .solar_systm .planet.saturn .trajectory.enc,
body .solar_systm .planet.saturn .trajectory.di,
body .solar_systm .planet.saturn .trajectory.ti,
body .solar_systm .planet.jupiter .trajectory.ga,
body .solar_systm .planet.jupiter .trajectory.eu,
body .solar_systm .planet.jupiter .trajectory.lop,
body .solar_systm .planet.mars .trajectory.p,
body .solar_systm .planet.mars .trajectory.d,
body .solar_systm .planet.earth .trajectory.m {
  border: 2px dashed white;
  position: absolute;
  border-radius: 3400px;
  background: none !important;
  z-index: -2;
}

body .solar_systm .planet {
  height: 1200px;
  width: 1200px;
  border-radius: 600px;
  background: red;
  transition: transform 2.8s 0.23s cubic-bezier(0.33, 0, 0, 1), opacity 2s 0.8s,
    box-shadow 0s 0s;
  background-size: 1140px 910px !important;
  top: auto;
  bottom: -920px;
}

@-webkit-keyframes planet {
  from {
    background-position-y: 0px;
  }

  to {
    background-position-y: -1000px;
  }
}

@keyframes planet {
  from {
    background-position-y: 0px;
  }

  to {
    background-position-y: -1000px;
  }
}

body .solar {
  position: absolute;
  top: 27.2px;
  transform: rotatex(-20deg);
  perspective: 800px;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

body input[type="radio"][name="planet"]::after {
  width: 220px;
  height: 220px;
  border-radius: 550px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  z-index: 12;
  left: 18px;
  bottom: 282px;
}

body .logo {
  color: white;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 4px;
}

body .logo span {
  font-size: 12px;
  color: #f39041;
  display: block;
}

body label {
  cursor: pointer;
}

body label.mercury > .preview {
  background: url("../images/2k_makemake_fictional.jpg") !important;
}

body label.venus > .preview {
  background: url("../images/ven0aaa2-copy-428-321.jpg") !important;
}

body label.earth > .preview {
  background: url("../images/new_earth_texture_map_by_lightondesigns.jpg") !important;
}

body label.mars > .preview {
  background: url("../images/mars_texture.jpg") !important;
}

body label.jupiter > .preview {
  background: url("../images/PIA07782_hires.jpg") !important;
}

body label.saturn > .preview {
  background: url("../images/2k_saturn.jpg") !important;
}

body label.uranus > .preview {
  background: url("../images/uranus_texture_map_by_jcpag2010-db7yjwb.png") !important;
}

body label.neptune > .preview {
  background: url("../images/neptune_texture_map_by_jcpag2010-dbcjcv5.png") !important;
}

body label.pluto > .preview {
  background: url("../images/pluto_map__2015_nov_10__by_snowfall_the_cat-d918tlb.png") !important;
}
body label.plan10 > .preview {
  background: url("../images/2k_saturn.jpg") !important;
}

body label.plan11 > .preview {
  background: url("../images/uranus_texture_map_by_jcpag2010-db7yjwb.png") !important;
}
body label.plan12 > .preview {
  background: url("../images/PIA07782_hires.jpg") !important;
}

body label.menu {
  color: white;
  display: block;
  position: absolute;
  cursor: pointer;
  left: 90px;
  z-index: 2;
}

body label.menu:after {
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 60px;
  content: "";
  z-index: 2;
  position: absolute;
  top: -4px;
  left: -4px;
}

body label.menu:hover h2,
body label.menu:hover h3 {
  opacity: 1;
}

body label.menu.mercury h2 .pip {
  background: #e8927c;
}

body label.menu.venus h2 .pip {
  background: #b45d15;
}

body label.menu.earth h2 .pip {
  background: #26daaa;
}

body label.menu.mars h2 .pip {
  background: #e55f45;
}

body label.menu.jupiter h2 .pip {
  background: orange;
}

body label.menu.saturn h2 .pip {
  background: #b29d81;
}

body label.menu.uranus h2 .pip {
  background: #8dcdd8;
}

body label.menu.neptune h2 .pip {
  background: #4f83e2;
}

body label.menu.pluto h2 .pip {
  background: #ff8732;
}
body label.menu.plan10 h2 .pip {
  background: #b29d81;
}

body label.menu.plan11 h2 .pip {
  background: #8dcdd8;
}
body label.menu.plan12 h2 .pip {
  background: orange;
}

body label.menu .preview {
  width: 30px;
  height: 30px;
  background: yellow;
  float: left;
  background-size: auto 100%;
  position: absolute;
  /* top: 7vh; */
  border-radius: 100px;
  box-shadow: 0 -13px 10px 2px black inset;
}

body label.menu .info {
  position: relative;
  left: 50px;
  /* top: 7vh; */
}



body label.menu h2,
body label.menu h3 {
  text-transform: uppercase;
  margin: 0;
  font-weight: 100;
  letter-spacing: 2px;
}

body label.menu h2 {
  font-size: 11px;
  opacity: 0.4;
  margin-bottom: 4px;
}

body label.menu h2 .pip {
  width: 0;
  height: 9px;
  background: #fea082;
  float: left;
  position: relative;
  top: 3px;
  transition: all 0.3s;
  margin-right: 0px;
}

body label.menu h3 {
  font-size: 8px;
  letter-spacing: 1px;
  transition: all 0.3s;
  opacity: 0.3;
}

body label.menu:nth-of-type(1) {
  top: calc(50vh + 100px + ((14px + 34px) * 1) + 0px - 369px);
}

body label.menu:nth-of-type(2) {
  top: calc(50vh + 100px + ((14px + 34px) * 2) + 0px - 369px);
}

body label.menu:nth-of-type(3) {
  top: calc(50vh + 100px + ((14px + 34px) * 3) + 0px - 369px);
}

body label.menu:nth-of-type(4) {
  top: calc(50vh + 100px + ((14px + 34px) * 4) + 0px - 369px);
}

body label.menu:nth-of-type(5) {
  top: calc(50vh + 100px + ((14px + 34px) * 5) + 0px - 369px);
}

body label.menu:nth-of-type(6) {
  top: calc(50vh + 100px + ((14px + 34px) * 6) + 0px - 369px);
}

body label.menu:nth-of-type(7) {
  top: calc(50vh + 100px + ((14px + 34px) * 7) + 0px - 369px);
}

body label.menu:nth-of-type(8) {
  top: calc(50vh + 100px + ((14px + 34px) * 8) + 0px - 369px);
}

body label.menu:nth-of-type(9) {
  top: calc(50vh + 100px + ((14px + 34px) * 9) + 0px - 369px);
}
body label.menu:nth-of-type(10) {
  top: calc(50vh + 100px + ((14px + 34px) * 10) + 0px - 369px);
}
body label.menu:nth-of-type(11) {
  top: calc(50vh + 100px + ((14px + 34px) * 11) + 0px - 369px);
}
body label.menu:nth-of-type(12) {
  top: calc(50vh + 100px + ((14px + 34px) * 12) + 0px - 369px);
}

body input.read {
  display: none;
}

body .read:checked + label + input + .panel {
  right: 0;
}

body .read:checked + label {
  width: calc(100% - 420px);
}

body .read:checked + label::after {
  opacity: 1;
  left: 0;
}

body .read:checked + label + label {
  transition: all 0.3s 0.6s;
  opacity: 1;
}

body .read:not(:checked) + label + label {
  transition: all 0.3s 0s;
  opacity: 0;
}

body label.close {
  position: absolute;
  right: 60px;
  opacity: 0;
  transition: all 0.3s 0.4s;
  z-index: 3;
  top: 65px;
}

body label.closeBig {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100vh;
  transition: all 0.2s;
  z-index: 10;
  width: 0;
  background: rgba(56, 37, 99, 0.38);
}

body label.closeBig::after {
  content: "Back";
  text-align: center;
  font-size: 30px;
  color: white;
  position: absolute;
  left: -140px;
  opacity: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

body .overlay {
  border-bottom: 1020px solid black;
  width: 1800px;
  height: 620px;
  top: auto;
  transform: none;
  top: -240px;
  left: -303px;
  opacity: 1;
  border-radius: 100%;
  z-index: 0;
  box-shadow: 0px -190px 215px 110px black inset;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet1:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet1:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -5;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -6;
}
body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-20700px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -7;
}
body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-23000px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -8;
}
body
  input[type="radio"][name="planet"].planet1:checked
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-25300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -9;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet1:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet1:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet1:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet1:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet1:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet2:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet2:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -5;
}
body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -6;
}
body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-20700px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -7;
}
body
  input[type="radio"][name="planet"].planet2:checked
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-23000px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -8;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet2:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet2:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet2:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet2:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet2:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet2:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet3:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet3:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}
body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -5;
}
body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -6;
}
body
  input[type="radio"][name="planet"].planet3:checked
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-20700px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -7;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet3:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet3:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet3:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet3:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet3:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet3:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet4:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet4:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}
body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}
body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -5;
}
body
  input[type="radio"][name="planet"].planet4:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -6;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet4:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet4:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet4:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet4:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet4:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet4:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet5:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet5:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}
body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}
body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}
body
  input[type="radio"][name="planet"].planet5:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -5;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet5:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet5:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet5:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet5:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet5:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet5:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet6:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet6:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 7;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}
body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}
body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}
body
  input[type="radio"][name="planet"].planet6:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet6:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet6:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet6:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet6:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet6:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet6:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet7:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet7:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 8;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 7;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}
body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}
body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}
body
  input[type="radio"][name="planet"].planet7:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet7:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet7:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet7:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet7:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet7:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet7:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet8:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet8:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 9;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 8;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 7;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}
body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}
body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}
body
  input[type="radio"][name="planet"].planet8:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-8100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet8:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet8:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet8:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet8:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet8:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet8:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet9:checked::after {
  display: none;
}

body input[type="radio"][name="planet"].planet9:checked + label + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 10;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 9;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 8;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 7;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}
body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}
body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}
body
  input[type="radio"][name="planet"].planet9:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet9:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet9:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet9:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet9:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet9:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet9:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}
/*  */

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet10:checked::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}
body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(20700px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 10;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 9;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 8;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 7;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}
body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}
body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}
body
  input[type="radio"][name="planet"].planet10:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet10:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet10:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet10:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet10:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet10:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet10:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

/*  */
/*  */
/*  */
/*  */

body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet11:checked::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}
body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}
body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(23000px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 10;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(20700px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 9;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 8;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 7;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}
body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
}
body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}
body
  input[type="radio"][name="planet"].planet11:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet11:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet11:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet11:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet11:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet11:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet11:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}

/* planet 12 */
/*  */


body input[type="radio"][name="planet"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body input[type="radio"][name="planet"]::after {
  content: "";
}

body input[type="radio"][name="planet"].planet12:checked::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}
body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}
body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input::after {
  display: none;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12) {
  pointer-events: all;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(1)
  .planet {
  transform: translateZ(25300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 10;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(2)
  .planet {
  transform: translateZ(23000px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 9;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(3)
  .planet {
  transform: translateZ(20700px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 8;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(4)
  .planet {
  transform: translateZ(18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 7;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(5)
  .planet {
  transform: translateZ(16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 6;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(6)
  .planet {
  transform: translateZ(13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 5;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(7)
  .planet {
  transform: translateZ(11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 4;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(8)
  .planet {
  transform: translateZ(9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}
body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(9)
  .planet {
  transform: translateZ(6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
}
body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(10)
  .planet {
  transform: translateZ(4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
}
body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(11)
  .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

body
  input[type="radio"][name="planet"].planet12.overlay
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet_description
  h2,
body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet_description
  h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet_description
  p,
body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet_description
  a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet
  .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet
  .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + input
  + label
  + div
  .solar_systm:nth-of-type(12)
  .planet
  .overlay {
  opacity: 1;
  top: -240px;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.mercury
  > .info
  h3 {
  color: #e8927c;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.venus
  > .info
  h3 {
  color: #b45d15;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.earth
  > .info
  h3 {
  color: #26daaa;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.mars
  > .info
  h3 {
  color: #e55f45;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.jupiter
  > .info
  h3 {
  color: orange;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.saturn
  > .info
  h3 {
  color: #b29d81;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.uranus
  > .info
  h3 {
  color: #8dcdd8;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.neptune
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.pluto
  > .info
  h3 {
  color: #ff8732;
}
body
  input[type="radio"][name="planet"].planet12:checked
  + label.plan10
  > .info
  h3 {
  color: #4f83e2;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label.plan11
  > .info
  h3 {
  color: #ff8732;
}

body input[type="radio"][name="planet"].planet12:checked + label {
  opacity: 1;
}

body input[type="radio"][name="planet"].planet12:checked + label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

body input[type="radio"][name="planet"].planet12:checked + label > .info h2 {
  opacity: 1;
}

body
  input[type="radio"][name="planet"].planet12:checked
  + label
  > .info
  h2
  .pip {
  width: 30px;
  margin-right: 6px;
}

body input[type="radio"][name="planet"].planet12:checked + label > .info h3 {
  opacity: 1;
  color: #fea082;
}


/*  */
/*  planet 12 */

body .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 2;
}

body .solar_systm:nth-of-type(1) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(1) .planet_description p,
body .solar_systm:nth-of-type(1) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(1) .planet_description h1,
body .solar_systm:nth-of-type(1) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 1;
}

body .solar_systm:nth-of-type(2) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(2) .planet_description p,
body .solar_systm:nth-of-type(2) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(2) .planet_description h1,
body .solar_systm:nth-of-type(2) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 0;
}

body .solar_systm:nth-of-type(3) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(3) .planet_description p,
body .solar_systm:nth-of-type(3) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(3) .planet_description h1,
body .solar_systm:nth-of-type(3) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -1;
}

body .solar_systm:nth-of-type(4) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(4) .planet_description p,
body .solar_systm:nth-of-type(4) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(4) .planet_description h1,
body .solar_systm:nth-of-type(4) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(5) .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -2;
}

body .solar_systm:nth-of-type(5) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(5) .planet_description p,
body .solar_systm:nth-of-type(5) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(5) .planet_description h1,
body .solar_systm:nth-of-type(5) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(6) .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -3;
}

body .solar_systm:nth-of-type(6) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(6) .planet_description p,
body .solar_systm:nth-of-type(6) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(6) .planet_description h1,
body .solar_systm:nth-of-type(6) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(7) .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -4;
}

body .solar_systm:nth-of-type(7) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(7) .planet_description p,
body .solar_systm:nth-of-type(7) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(7) .planet_description h1,
body .solar_systm:nth-of-type(7) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(8) .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -5;
}

body .solar_systm:nth-of-type(8) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(8) .planet_description p,
body .solar_systm:nth-of-type(8) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(8) .planet_description h1,
body .solar_systm:nth-of-type(8) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(9) .planet {
  transform: translateZ(-18400px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -6;
}

body .solar_systm:nth-of-type(9) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(9) .planet_description p,
body .solar_systm:nth-of-type(9) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(9) .planet_description h1,
body .solar_systm:nth-of-type(9) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm:nth-of-type(10) .planet {
  transform: translateZ(-20700px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -7;
}

body .solar_systm:nth-of-type(10) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(10) .planet_description p,
body .solar_systm:nth-of-type(10) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(10) .planet_description h1,
body .solar_systm:nth-of-type(10) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}


body .solar_systm:nth-of-type(11) .planet {
  transform: translateZ(-20700px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -7;
}

body .solar_systm:nth-of-type(11) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(11) .planet_description p,
body .solar_systm:nth-of-type(11) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(11) .planet_description h1,
body .solar_systm:nth-of-type(11) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}





body .solar_systm:nth-of-type(12) .planet {
  transform: translateZ(-23000px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -7;
}

body .solar_systm:nth-of-type(12) .planet_description {
  opacity: 1;
}

body .solar_systm:nth-of-type(12) .planet_description p,
body .solar_systm:nth-of-type(12) .planet_description a {
  opacity: 0;
}

body .solar_systm:nth-of-type(12) .planet_description h1,
body .solar_systm:nth-of-type(12) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

body .solar_systm .planet .trajectory {
  transition: all 0.6s 0s;
  opacity: 0;
}

body .solar_systm .planet.mercury {
  background: url("../images/2k_makemake_fictional.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset,
    0 -10px 130px rgba(188, 143, 127, 0.6);
}

body .solar_systm .planet.venus {
  background: url("../images/ven0aaa2-copy-428-321.jpg");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #ffcb9c inset,
    0 0px 23px 4px #ffcb9c inset, 0 -10px 130px #b85a07;
}

body .solar_systm .planet.earth {
  background: url("../images/new_earth_texture_map_by_lightondesigns.jpg");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #8cbaff inset,
    0 0px 23px 4px #8cbaff inset, 0 -10px 130px #7894a9;
}

body .solar_systm .planet.mars {
  background: url("../images/mars_texture.jpg");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #e86363 inset,
    0 0px 23px 4px #e86363 inset, 0 -10px 130px #6b261a;
}

body .solar_systm .planet.jupiter {
  background: url("../images/PIA07782_hires.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset,
    0 -10px 130px rgba(188, 143, 127, 0.6);
}

body .solar_systm .planet.saturn {
  background: url("../images/2k_saturn.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset,
    0 -10px 130px rgba(188, 143, 127, 0.6);
}

body .solar_systm .planet.uranus {
  background: url("../images/uranus_texture_map_by_jcpag2010-db7yjwb.png");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset,
    0 -10px 130px rgba(127, 188, 171, 0.6);
}

body .solar_systm .planet.neptune {
  background: url("../images/2k_neptune.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
}

body .solar_systm .planet.pluto {
  background: url("../images/NereidTxt.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
}
body .solar_systm .planet.plan10 {
  background: url("../images/2k_neptune.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
}

body .solar_systm .planet.plan11 {
  background: url("../images/NereidTxt.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
}
body .solar_systm .planet.plan12 {
  background: url("../images/PIA07782_hires.jpg");
  box-shadow: 0 -590px 150px black inset,
    0 0px 130px 40px rgba(234, 205, 199, 0.6) inset,
    0 0px 23px 4px rgba(234, 205, 199, 0.6) inset, 0 -10px 130px #2d4153;
}

body .solar_systm .planet_description {
  width: 620px;
  text-align: center;
  position: absolute;
  margin: auto;
  left: 0;
  z-index: 2;
  right: 0;
  color: white;
  font-weight: 100;
  transition: all 0.4s 0s;
  text-transform: uppercase;
  z-index: 1;
}

body .solar_systm .planet_description.mercury a {
  color: #e8927c;
}

body .solar_systm .planet_description.mercury h2 {
  color: #f7dad3;
}

body .solar_systm .planet_description.venus a {
  color: #b45d15;
}

body .solar_systm .planet_description.venus h2 {
  color: #e99046;
}

body .solar_systm .planet_description.earth a {
  color: #26daaa;
}

body .solar_systm .planet_description.earth h2 {
  color: #7de9cc;
}

body .solar_systm .planet_description.mars a {
  color: #e55f45;
}

body .solar_systm .planet_description.mars h2 {
  color: #f2ac9e;
}

body .solar_systm .planet_description.jupiter a {
  color: orange;
}

body .solar_systm .planet_description.jupiter h2 {
  color: #ffc966;
}

body .solar_systm .planet_description.saturn a {
  color: #b29d81;
}

body .solar_systm .planet_description.saturn h2 {
  color: #d9cec0;
}

body .solar_systm .planet_description.uranus a {
  color: #8dcdd8;
}

body .solar_systm .planet_description.uranus h2 {
  color: #d9eef2;
}

body .solar_systm .planet_description.neptune a {
  color: #4f83e2;
}

body .solar_systm .planet_description.neptune h2 {
  color: #a7c1f0;
}

body .solar_systm .planet_description.pluto a {
  color: #ff8732;
}

body .solar_systm .planet_description.pluto h2 {
  color: #ffc398;
}
body .solar_systm .planet_description.plan10 a {
  color: #4f83e2;
}

body .solar_systm .planet_description.plan10 h2 {
  color: #a7c1f0;
}

body .solar_systm .planet_description.plan11 a {
  color: #ff8732;
}

body .solar_systm .planet_description.plan11 h2 {
  color: #ffc398;
}

body .solar_systm .planet_description h1,
body .solar_systm .planet_description h2,
body .solar_systm .planet_description p,
body .solar_systm .planet_description a {
  font-weight: 100;
  font-size: 10px;
  letter-spacing: 5px;
  margin: 0;
}

body .solar_systm .planet_description h1 {
  letter-spacing: 16px;
  font-size: 34px;
}

body .solar_systm .planet_description h2,
body .solar_systm .planet_description a {
  color: #fabfad;
}

body .solar_systm .planet_description h2 {
  margin-top: 60px;
  margin-bottom: 6px;
}

body .solar_systm .planet_description p {
  line-height: 26px;
  margin-top: 14px;
  opacity: 0.9;
  margin-bottom: 10px;
}

body .solar_systm .planet_description a {
  color: #fea082;
  font-size: 11px;
  font-weight: 500;
  padding: 0 2px 5px 0px;
  transition: all 0.2s;
  position: relative;
  left: 0;
}

body .solar_systm .planet_description a:hover {
  padding: 0 10px 5px 10px;
  left: -1px;
}

body .solar_systm .planet_description a span {
  letter-spacing: 0;
  margin-left: -5px;
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: rgb(50, 47, 47);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin: 5px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

body label.menu h2{
  font-size: 10px;
}
/* body label.menu, body input.inpu{
  position: relative;
} */
@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}
