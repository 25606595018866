#nssc_eventmobile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: -34vw;
}
#nssc_eventmobile a {
  text-decoration: none;
}
#sun10 {
  display: flex;
  justify-content: center;
  align-items: center;
}
#sun10 img {
  width: 60vw;
  animation: zoom-in 1.3s ease-in-out 1;
}

#planet1,
#planet2,
#planet3,
#planet4,
#planet5,
#planet6,
#planet7 {
  width: 75vw;
}

#rogleg2{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  font-size: 1.7rem;
}

.login-reg2{
  border: solid 2px rgb(255, 255, 255);
  border-radius: 30px;
  text-decoration: none;
  color: #fff;
  padding: 0 1rem;
}

#planet1 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateX(-18vw) ; */
}
#planet1 img {
  width: 15vw;
  margin: 10px 0px;
  position: relative;
  animation: zoom-in 1.3s ease-in-out 1;
}
#planet2 {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(25vw);
}
#planet2 img {
  width: 16vw;
  position: relative;
  margin: 10px 0px;
  animation: zoom-in 1.3s ease-in-out 1;
}
#planet3 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateX(-18vw) ; */
}
#planet3 img {
  width: 19vw;
  margin: 10px 0px;
  position: relative;
  animation: zoom-in 1.3s ease-in-out 1;
}
#planet4 {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(25vw);
}
#planet4 img {
  width: 20vw;
  margin: 15px 0px;
  position: relative;
  animation: zoom-in 1.3s ease-in-out 1;
}
#planet5 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateX(-18vw) ; */
}
#planet5 img {
  width: 23vw;
  margin: 15px 0px;
  position: relative;
  animation: zoom-in 1.3s ease-in-out 1;
}
#planet6 {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(25vw);
}
#planet6 img {
  width: 25vw;
  margin: 15px 0px;
  position: relative;
  animation: zoom-in 1.3s ease-in-out 1;
}
#planet7 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateX(-18vw) ; */
}
#planet7 img {
  width: 23vw;
  margin: 15px 0px;
  position: relative;
  animation: zoom-in 1.3s ease-in-out 1;
}
.planet_inside {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.txt_events {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: bold;
  animation: left 1.3s ease-in-out 1;
  text-align: center;
}

.background-mobile {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: -1;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  80% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes left {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
