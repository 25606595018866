@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@600&family=Ubuntu&display=swap");

#contact-section-wrapper input,
textarea,
button,a  {
  font-family: "Roboto", sans-serif;
}
#contact-section-wrapper {
  width: 100%;
  overflow-x: hidden;
}
#contact-section-wrapper * {
  box-sizing: border-box;
}
.contact-box-wrapper {
  /* position: absolute; */
  display: table;
  width: 1100px;
  margin: auto;
  margin-top: 10px;
  border-radius: 30px;
}
.contact-info-wrap {
  width: 35%;
  height: 551px;
  padding: 40px 30px;
  float: left;
  display: block;
  border-radius: 30px 0px 0px 30px;
  background: linear-gradient(
    144deg,
    rgba(126, 39, 156, 1) 0%,
    rgba(49, 39, 157, 1) 49%
  );
  color: #fff;
}
/* .contact-info-title {
  text-align: left;
  font-size: 28px;
  letter-spacing: 0.5px;
} */
.contact-info-sub-title {
  font-size: 18px;
  font-weight: 300;
  margin-top: 17px;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.contact-info-details {
  list-style: none;
  margin: 60px 0px;
}

.contact-info-details li {
  margin-top: 25px;
  font-size: 18px;
  color: #fff;
}
.contact-info-details li i {
  background: #1f181a;
  padding: 12px;
  border-radius: 50%;
  margin-right: 5px;
}
.contact-info-details li a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}
.contact-info-details li a:hover {
  color: #f44770;
}
.contact-social-icons {
  list-style: none;
  text-align: center;
  margin: 20px 0px;
}
.contact-social-icons li {
  display: inline-block;
}
.contact-social-icons li i {
  background: #23181a;
  color: #fff;
  padding: 15px;
  font-size: 20px;
  border-radius: 22%;
  margin: 0px 7px;
  cursor: pointer;
  transition: all 0.2s;
}
.contact-social-icons li i:hover {
  background: #03e9f4;
  color: #000000;
}
.contact-form-wrap {
  width: 65%;
  float: right;
  padding: 22px 25px 18px 25px;
  border-radius: 0px 30px 30px 0px;
  background: rgba(33, 32, 32, 0.5);
}
.contact-form-title {
  text-align: left;
  margin-left: 23px;
  font-size: 28px;
  letter-spacing: 0.5px;
  color: #fff;
}
.contact-form-fields {
  display: table;
  width: 100%;
  padding: 15px 5px 5px 5px;
}
.contact-form-fields input,
.contact-form-fields textarea {
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  color: rgb(255, 255, 255);
  padding: 20px 10px 20px 5px;
  width: 100%;
}
.contact-form-fields textarea {
  height: 150px;
  resize: none;
}
.contact-form-group {
  width: 46%;
  float: left;
  padding: 0px 30px;
  margin: 14px 12px;
  border-radius: 15px;
  box-shadow: inset 5px 5px 5px #213346, inset -5px -5px 5px #937171;
}
.contact-form-fields .contact-form-group:last-child {
  width: 90%;
}

.contact-form-wrap form button {
  position: relative;
  display: inline-block;
  padding: 14px 32px;
  color: white;
  border: none;
  background-color: transparent;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 20px;
  margin-left: 50px;
  letter-spacing: 4px;
  cursor: pointer;
}

.contact-form-wrap button:hover {
  background: linear-gradient(
    144deg,
    rgba(126, 39, 156, 1) 0%,
    rgba(49, 39, 157, 1) 49%
  );
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(126, 39, 156, 1);
}

.contact-form-wrap button span {
  position: absolute;
  display: block;
}

.contact-form-wrap button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgba(126, 39, 156, 1));
  animation: contact-btn-anim1 1s linear infinite;
}

@keyframes contact-btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.contact-form-wrap button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, rgba(126, 39, 156, 1));
  animation: contact-btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes contact-btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.contact-form-wrap button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, rgba(126, 39, 156, 1));
  animation: contact-btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes contact-btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.contact-form-wrap button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, rgba(126, 39, 156, 1));
  animation: contact-btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes contact-btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

/* Responsive css */

@media only screen and (max-width: 1100px) {
  .contact-box-wrapper {
    width: 90%;
    margin-bottom: 30px;
  }
  .contact-info-wrap,
  .contact-form-wrap {
    width: 100%;
    height: inherit;
    float: none;
  }
  .contact-info-wrap {
    border-radius: 30px 30px 0px 0px;
  }
  .contact-form-wrap {
    border-radius: 0px 0px 30px 30px;
  }
  .contact-form-group {
    width: 100%;
    float: none;
    margin: 25px 0px;
  }
  .contact-form-fields .contact-form-group:last-child,
  .submit-button {
    width: 100%;
  }
  .submit-button {
    margin: 10px 0px;
  }
}

.waviy span {
  position: relative;
  display: inline-block;
  font-family: "Ubuntu", sans-serif;
  font-size: 40px;
  color: #fff;
  text-transform: uppercase;
}

@keyframes contact-flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.contact-lineUp {
  color: #fff;
  font-family: "Source Serif Pro", serif;
  font-size: 3rem;
}
@keyframes anim-contact-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
