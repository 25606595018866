/* @import url('https://fonts.googleapis.com/css2?family=Rubik+Dirt&display=swap'); */

.navbar {
  padding: 1rem;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.navbar h1 {
  color: #00A8F3;
  font-family: 'Rubik Dirt', cursive;
}

.navbar h1 span {
  color: #525252;
  font-family: 'Rubik Dirt', cursive;
}

/* .navbar .links {
  margin-left: auto;
} */

.navbar a {
  /* margin-left: 1%; */
  text-decoration: none;
  padding: 1%;
  color: white;
  font-family: monospace;
  font-size: 1.2rem;
}

.navbar a:hover {
  color: #f1356d;
}

.links {
  display: flex;
  justify-content: flex-end;
  align-content:flex-end;
  align-items: center;
  width: 100%;
  z-index: 50;
}

.navbar-logo {
  width: 25vw;
  height: auto;
  /* margin-right: 10px; */
  z-index: 50;
}

@media screen and (max-width: 1450px) {
  .navbar{
    max-width:115%;
  }
  .navbar a{
    font-size:1.1rem;
  }
}
@media screen and (max-width: 1280px) {
  .navbar a{
    font-size:1rem;
  }
}
@media screen and (max-width: 1200px) {
  .navbar a{
    font-size:0.9rem;
  }
}