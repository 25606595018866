@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');

.guest-page-container {
  background-color: rgba(32, 33, 32, 0.5);
  border-radius: 1rem;
  margin: 4rem 10%;
  padding-top: 1.25rem;
}

.guest-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 6rem;
  position: relative;
  z-index: 10;
}

.speaker-image{
  width: 25vw;
  height: auto;
}
.rules span {
  font-size: 1.8rem;
  color: #d07000;
}

#know-more-heading {
  font-size: 4rem;
  /* font-family: Georgia, "Times New Roman", Times, serif; */
  text-align: center;
  margin: 1rem 0;
  letter-spacing: 0.5rem;
  color: #fff5e4;
}

.content-know-more {
  text-align: center;
  margin: 20px auto;
  width: 75%;
}

.know-more-heading {
  font-size: 2.5rem;
  font-family: "Josefin Sans",cursive;
  color: #6f62b5;
  margin-top: 0;
}

.know-more-description {
  font-size: 1.2rem;
  /* font-family: "Times New Roman", Times, serif; */
  margin: 40px 1rem;
  line-height: 25px;
}
.rules {
  text-align: left;
  margin: 1rem;
}
.rules ul {
  padding: 0px 1.5rem;
}
.eventscontact {
  margin: 0rem auto;
  text-align: left;
  width: 100%;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-size: 1.2rem;
  padding-bottom: 3rem;
  color: #f1356d;
}
.eventscontact div span {
  color: #f1356d;
  margin: 0px 1.2rem;
}
.eventscontact div {
  margin: 0.5rem;
  text-align: center;
}
.imgspan img {
  width: 1.5rem;
}
.eventscontactinside {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  #know-more-heading {
    font-size: 2.5rem;
    letter-spacing: 0px;
  }
  .speaker-image{
    width: 50vw;
    height: auto;
  }
  .know-more-heading {
    font-size: 1.9rem;
  }
  .content-know-more{
    width: 100%;
  }
}
