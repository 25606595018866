.spons-page {
  background-color: white;
  position: relative;
  margin: 0% 18%;
  display: grid;
  align-items: center;
  justify-content: center;
}
.spons-img img {
  width: 20vw;
}
.sponsor-inner h1 {
  font-size: 4rem;
  margin: 2rem 0rem;
  margin-bottom: 2rem;
  color: #270f72;
  text-align: center;
  letter-spacing: 3px;
}
.spons-section {
  font-size: 3vw;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: rgb(64 95 186);
  text-align: center;
  letter-spacing: 3px;
}
.spons-imgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 3rem 0rem;
}
.spons-img {
  margin: 1.5rem 1.5rem;
}

/* Add a black background color to the top navigation */
.spons-topnav {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  padding-top: 4rem;
  display: flex;
  margin: 0% 18%;
  align-items: center;
  justify-content: center;
}

/* Style the links inside the navigation bar */
.spons-topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 50px;
  width: 33.33%;
  font-family: monospace;
  background-color: #3b406c;
  border: #00ffd74a solid;
  cursor: pointer;
  transition: all 0.3s;
}

@media (max-width: 1100px) {
  .spons-topnav a {
    font-size: 30px;
    width: 15rem;
  }
  .spons-section{
    font-size: 5vw;
  }
  .app {
    position: relative;
  }
  .sponsor-inner h1 {
    font-size: 2.1rem;
  }
}
@media (max-width: 515px){
  .spons-topnav{
    margin: 0% 10vw;
  }
  .spons-topnav a{
    padding: 5px 5px;
  }
  .spons-page{
    margin: 0% 10vw;
  }
}
/* Change the color of links on hover */
.spons-topnav a:hover {
  background-color: #b93160;
  color: black;
}

/* Add a color to the active/current link */
.spons-topnav a.active {
  background-color: #04aa6d;
  color: white;
}
#krishna{
  width: 20vw;
}
