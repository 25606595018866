* {
  margin: 0px;
  padding: 0px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600&display=swap');

body {
  font-family: 'Roboto Mono', monospace;
  /* font-family: Montserrat, sans-serif, Poppins; */
  transition: all 0.3s ease-in;
  color: white;
  background-color: #00050c;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  /* padding: 0% 10% 0% 10%; */
}
@media (max-width: 1100px) {
  .navbar {
    display: none;
  }
}
@media (min-width: 1101px) {
  .navbar_M {
    display: none;
  }
}
.hidden {
  display: none !important;
}
