.stars-outer {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
#stars-sky {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}
.stars-star {
  opacity: 0;
  transform-origin: 0, 0;
  position: absolute;
  background: white;
  box-shadow: 0px 0px 20px 0px #fff;
  border-radius: 100%;
  animation-timing-function: linear, linear !important;
  animation-iteration-count: infinite, infinite !important;
  animation-delay: -30s, -30s !important;
}
.stars-size-1 {
  width: 0.5px;
  height: 0.5px;
}
.stars-size-2 {
  width: 1px;
  height: 1px;
}
.stars-size-3 {
  width: 2px;
  height: 2px;
}
.stars-axis-1 {
  animation: anim0 12833ms, fade10 12833ms;
}
.stars-axis-2 {
  animation: anim0 13749ms, fade1 13749ms;
}
.stars-axis-3 {
  animation: anim0 36963ms, fade2 36963ms;
}
.stars-axis-4 {
  animation: anim4 13801ms, fade7 13801ms;
}
.stars-axis-5 {
  animation: anim4 24230ms, fade8 24230ms;
}
.stars-axis-6 {
  animation: anim4 33379ms, fade6 33379ms;
}
.stars-axis-7 {
  animation: anim4 9693ms, fade4 9693ms;
}
.stars-axis-8 {
  animation: anim8 37245ms, fade6 37245ms;
}
.stars-axis-9 {
  animation: anim8 8949ms, fade6 8949ms;
}
.stars-axis-10 {
  animation: anim8 33192ms, fade10 33192ms;
}
.stars-axis-11 {
  animation: anim8 31929ms, fade9 31929ms;
}
.stars-axis-12 {
  animation: anim12 19830ms, fade10 19830ms;
}
.stars-axis-13 {
  animation: anim12 33105ms, fade2 33105ms;
}
.stars-axis-14 {
  animation: anim12 9497ms, fade5 9497ms;
}
.stars-axis-15 {
  animation: anim12 17943ms, fade9 17943ms;
}
.stars-axis-16 {
  animation: anim16 28271ms, fade6 28271ms;
}
.stars-axis-17 {
  animation: anim16 27355ms, fade9 27355ms;
}
.stars-axis-18 {
  animation: anim16 41723ms, fade6 41723ms;
}
.stars-axis-19 {
  animation: anim16 37146ms, fade8 37146ms;
}
.stars-axis-20 {
  animation: anim20 33227ms, fade4 33227ms;
}
.stars-axis-21 {
  animation: anim20 29623ms, fade8 29623ms;
}
.stars-axis-22 {
  animation: anim20 42234ms, fade1 42234ms;
}
.stars-axis-23 {
  animation: anim20 19973ms, fade9 19973ms;
}
.stars-axis-24 {
  animation: anim24 13097ms, fade9 13097ms;
}
.stars-axis-25 {
  animation: anim24 32207ms, fade2 32207ms;
}
.stars-axis-26 {
  animation: anim24 32760ms, fade7 32760ms;
}
.stars-axis-27 {
  animation: anim24 42311ms, fade3 42311ms;
}
.stars-axis-28 {
  animation: anim28 15141ms, fade4 15141ms;
}
.stars-axis-29 {
  animation: anim28 42758ms, fade7 42758ms;
}
.stars-axis-30 {
  animation: anim28 34090ms, fade4 34090ms;
}
.stars-axis-31 {
  animation: anim28 10219ms, fade3 10219ms;
}
.stars-axis-32 {
  animation: anim32 39121ms, fade2 39121ms;
}
.stars-axis-33 {
  animation: anim32 8905ms, fade3 8905ms;
}
.stars-axis-34 {
  animation: anim32 20647ms, fade1 20647ms;
}
.stars-axis-35 {
  animation: anim32 44901ms, fade4 44901ms;
}
.stars-axis-36 {
  animation: anim36 35838ms, fade6 35838ms;
}
.stars-axis-37 {
  animation: anim36 41344ms, fade4 41344ms;
}
.stars-axis-38 {
  animation: anim36 7357ms, fade5 7357ms;
}
.stars-axis-39 {
  animation: anim36 38756ms, fade7 38756ms;
}
.stars-axis-40 {
  animation: anim40 21714ms, fade5 21714ms;
}
.stars-axis-41 {
  animation: anim40 34650ms, fade7 34650ms;
}
.stars-axis-42 {
  animation: anim40 25909ms, fade10 25909ms;
}
.stars-axis-43 {
  animation: anim40 9875ms, fade10 9875ms;
}
.stars-axis-44 {
  animation: anim44 26804ms, fade6 26804ms;
}
.stars-axis-45 {
  animation: anim44 32856ms, fade6 32856ms;
}
.stars-axis-46 {
  animation: anim44 34990ms, fade2 34990ms;
}
.stars-axis-47 {
  animation: anim44 8120ms, fade7 8120ms;
}
.stars-axis-48 {
  animation: anim48 6282ms, fade7 6282ms;
}
.stars-axis-49 {
  animation: anim48 8738ms, fade4 8738ms;
}
.stars-axis-50 {
  animation: anim48 39307ms, fade4 39307ms;
}
.stars-axis-51 {
  animation: anim48 5146ms, fade9 5146ms;
}
.stars-axis-52 {
  animation: anim52 11727ms, fade9 11727ms;
}
.stars-axis-53 {
  animation: anim52 9734ms, fade9 9734ms;
}
.stars-axis-54 {
  animation: anim52 11194ms, fade6 11194ms;
}
.stars-axis-55 {
  animation: anim52 40570ms, fade5 40570ms;
}
.stars-axis-56 {
  animation: anim56 16196ms, fade9 16196ms;
}
.stars-axis-57 {
  animation: anim56 25872ms, fade5 25872ms;
}
.stars-axis-58 {
  animation: anim56 20950ms, fade7 20950ms;
}
.stars-axis-59 {
  animation: anim56 43061ms, fade4 43061ms;
}
.stars-axis-60 {
  animation: anim60 26657ms, fade1 26657ms;
}
.stars-axis-61 {
  animation: anim60 36577ms, fade5 36577ms;
}
.stars-axis-62 {
  animation: anim60 20160ms, fade8 20160ms;
}
.stars-axis-63 {
  animation: anim60 27253ms, fade6 27253ms;
}
.stars-axis-64 {
  animation: anim64 20029ms, fade1 20029ms;
}
.stars-axis-65 {
  animation: anim64 36147ms, fade9 36147ms;
}
.stars-axis-66 {
  animation: anim64 7718ms, fade4 7718ms;
}
.stars-axis-67 {
  animation: anim64 21145ms, fade5 21145ms;
}
.stars-axis-68 {
  animation: anim68 39192ms, fade1 39192ms;
}
.stars-axis-69 {
  animation: anim68 24361ms, fade5 24361ms;
}
.stars-axis-70 {
  animation: anim68 42961ms, fade9 42961ms;
}
.stars-axis-71 {
  animation: anim68 25993ms, fade9 25993ms;
}
.stars-axis-72 {
  animation: anim72 16065ms, fade4 16065ms;
}
.stars-axis-73 {
  animation: anim72 35557ms, fade9 35557ms;
}
.stars-axis-74 {
  animation: anim72 30825ms, fade2 30825ms;
}
.stars-axis-75 {
  animation: anim72 12049ms, fade3 12049ms;
}
.stars-axis-76 {
  animation: anim76 21807ms, fade9 21807ms;
}
.stars-axis-77 {
  animation: anim76 42738ms, fade4 42738ms;
}
.stars-axis-78 {
  animation: anim76 42312ms, fade8 42312ms;
}
.stars-axis-79 {
  animation: anim76 40738ms, fade7 40738ms;
}
.stars-axis-80 {
  animation: anim80 35906ms, fade10 35906ms;
}
.stars-axis-81 {
  animation: anim80 37049ms, fade2 37049ms;
}
.stars-axis-82 {
  animation: anim80 6419ms, fade3 6419ms;
}
.stars-axis-83 {
  animation: anim80 19704ms, fade6 19704ms;
}
.stars-axis-84 {
  animation: anim84 20705ms, fade9 20705ms;
}
.stars-axis-85 {
  animation: anim84 13238ms, fade2 13238ms;
}
.stars-axis-86 {
  animation: anim84 28122ms, fade3 28122ms;
}
.stars-axis-87 {
  animation: anim84 44931ms, fade1 44931ms;
}
.stars-axis-88 {
  animation: anim88 32014ms, fade3 32014ms;
}
.stars-axis-89 {
  animation: anim88 41876ms, fade3 41876ms;
}
.stars-axis-90 {
  animation: anim88 39300ms, fade2 39300ms;
}
.stars-axis-91 {
  animation: anim88 8588ms, fade5 8588ms;
}
.stars-axis-92 {
  animation: anim92 22017ms, fade2 22017ms;
}
.stars-axis-93 {
  animation: anim92 32006ms, fade5 32006ms;
}
.stars-axis-94 {
  animation: anim92 34211ms, fade3 34211ms;
}
.stars-axis-95 {
  animation: anim92 17235ms, fade8 17235ms;
}
.stars-axis-96 {
  animation: anim96 23036ms, fade1 23036ms;
}
.stars-axis-97 {
  animation: anim96 27440ms, fade4 27440ms;
}
.stars-axis-98 {
  animation: anim96 16741ms, fade2 16741ms;
}
.stars-axis-99 {
  animation: anim96 25200ms, fade10 25200ms;
}
.stars-axis-100 {
  animation: anim100 28732ms, fade3 28732ms;
}
.stars-axis-101 {
  animation: anim100 43114ms, fade5 43114ms;
}
.stars-axis-102 {
  animation: anim100 19534ms, fade2 19534ms;
}
.stars-axis-103 {
  animation: anim100 9894ms, fade10 9894ms;
}
.stars-axis-104 {
  animation: anim104 14347ms, fade3 14347ms;
}
.stars-axis-105 {
  animation: anim104 35242ms, fade8 35242ms;
}
.stars-axis-106 {
  animation: anim104 35869ms, fade1 35869ms;
}
.stars-axis-107 {
  animation: anim104 10272ms, fade2 10272ms;
}
.stars-axis-108 {
  animation: anim108 15248ms, fade7 15248ms;
}
.stars-axis-109 {
  animation: anim108 20624ms, fade5 20624ms;
}
.stars-axis-110 {
  animation: anim108 44547ms, fade5 44547ms;
}
.stars-axis-111 {
  animation: anim108 8823ms, fade4 8823ms;
}
.stars-axis-112 {
  animation: anim112 8256ms, fade10 8256ms;
}
.stars-axis-113 {
  animation: anim112 22735ms, fade8 22735ms;
}
.stars-axis-114 {
  animation: anim112 18519ms, fade2 18519ms;
}
.stars-axis-115 {
  animation: anim112 43677ms, fade1 43677ms;
}
.stars-axis-116 {
  animation: anim116 34424ms, fade1 34424ms;
}
.stars-axis-117 {
  animation: anim116 17441ms, fade3 17441ms;
}
.stars-axis-118 {
  animation: anim116 26731ms, fade2 26731ms;
}
.stars-axis-119 {
  animation: anim116 21954ms, fade8 21954ms;
}
.stars-axis-120 {
  animation: anim120 39040ms, fade2 39040ms;
}
.stars-axis-121 {
  animation: anim120 34467ms, fade7 34467ms;
}
.stars-axis-122 {
  animation: anim120 36709ms, fade3 36709ms;
}
.stars-axis-123 {
  animation: anim120 29201ms, fade7 29201ms;
}
.stars-axis-124 {
  animation: anim124 27681ms, fade8 27681ms;
}
.stars-axis-125 {
  animation: anim124 34761ms, fade10 34761ms;
}
.stars-axis-126 {
  animation: anim124 26269ms, fade4 26269ms;
}
.stars-axis-127 {
  animation: anim124 31531ms, fade10 31531ms;
}
.stars-axis-128 {
  animation: anim128 32458ms, fade10 32458ms;
}
.stars-axis-129 {
  animation: anim128 30508ms, fade8 30508ms;
}
.stars-axis-130 {
  animation: anim128 5234ms, fade6 5234ms;
}
.stars-axis-131 {
  animation: anim128 25093ms, fade3 25093ms;
}
.stars-axis-132 {
  animation: anim132 18368ms, fade9 18368ms;
}
.stars-axis-133 {
  animation: anim132 35845ms, fade4 35845ms;
}
.stars-axis-134 {
  animation: anim132 18061ms, fade2 18061ms;
}
.stars-axis-135 {
  animation: anim132 20772ms, fade6 20772ms;
}
.stars-axis-136 {
  animation: anim136 40453ms, fade3 40453ms;
}
.stars-axis-137 {
  animation: anim136 21157ms, fade8 21157ms;
}
.stars-axis-138 {
  animation: anim136 27974ms, fade5 27974ms;
}
.stars-axis-139 {
  animation: anim136 9050ms, fade10 9050ms;
}
.stars-axis-140 {
  animation: anim140 26681ms, fade1 26681ms;
}
.stars-axis-141 {
  animation: anim140 5623ms, fade4 5623ms;
}
.stars-axis-142 {
  animation: anim140 31127ms, fade9 31127ms;
}
.stars-axis-143 {
  animation: anim140 18365ms, fade9 18365ms;
}
.stars-axis-144 {
  animation: anim144 36673ms, fade2 36673ms;
}
.stars-axis-145 {
  animation: anim144 42889ms, fade6 42889ms;
}
.stars-axis-146 {
  animation: anim144 24483ms, fade4 24483ms;
}
.stars-axis-147 {
  animation: anim144 38268ms, fade8 38268ms;
}
.stars-axis-148 {
  animation: anim148 35700ms, fade6 35700ms;
}
.stars-axis-149 {
  animation: anim148 16716ms, fade2 16716ms;
}
.stars-axis-150 {
  animation: anim148 6212ms, fade6 6212ms;
}
.stars-axis-151 {
  animation: anim148 10623ms, fade2 10623ms;
}
.stars-axis-152 {
  animation: anim152 17190ms, fade10 17190ms;
}
.stars-axis-153 {
  animation: anim152 39238ms, fade3 39238ms;
}
.stars-axis-154 {
  animation: anim152 17724ms, fade5 17724ms;
}
.stars-axis-155 {
  animation: anim152 19865ms, fade8 19865ms;
}
.stars-axis-156 {
  animation: anim156 32428ms, fade7 32428ms;
}
.stars-axis-157 {
  animation: anim156 11300ms, fade9 11300ms;
}
.stars-axis-158 {
  animation: anim156 25254ms, fade8 25254ms;
}
.stars-axis-159 {
  animation: anim156 31339ms, fade9 31339ms;
}
.stars-axis-160 {
  animation: anim160 21565ms, fade6 21565ms;
}
.stars-axis-161 {
  animation: anim160 5453ms, fade7 5453ms;
}
.stars-axis-162 {
  animation: anim160 34566ms, fade3 34566ms;
}
.stars-axis-163 {
  animation: anim160 8487ms, fade3 8487ms;
}
.stars-axis-164 {
  animation: anim164 24052ms, fade7 24052ms;
}
.stars-axis-165 {
  animation: anim164 30242ms, fade2 30242ms;
}
.stars-axis-166 {
  animation: anim164 13892ms, fade5 13892ms;
}
.stars-axis-167 {
  animation: anim164 40539ms, fade3 40539ms;
}
.stars-axis-168 {
  animation: anim168 10723ms, fade6 10723ms;
}
.stars-axis-169 {
  animation: anim168 39998ms, fade8 39998ms;
}
.stars-axis-170 {
  animation: anim168 38310ms, fade6 38310ms;
}
.stars-axis-171 {
  animation: anim168 22356ms, fade5 22356ms;
}
.stars-axis-172 {
  animation: anim172 36109ms, fade3 36109ms;
}
.stars-axis-173 {
  animation: anim172 13629ms, fade5 13629ms;
}
.stars-axis-174 {
  animation: anim172 19076ms, fade7 19076ms;
}
.stars-axis-175 {
  animation: anim172 35879ms, fade1 35879ms;
}
.stars-axis-176 {
  animation: anim176 31219ms, fade7 31219ms;
}
.stars-axis-177 {
  animation: anim176 38694ms, fade10 38694ms;
}
.stars-axis-178 {
  animation: anim176 29328ms, fade6 29328ms;
}
.stars-axis-179 {
  animation: anim176 13853ms, fade7 13853ms;
}
.stars-axis-180 {
  animation: anim180 22595ms, fade1 22595ms;
}
.stars-axis-181 {
  animation: anim180 22272ms, fade4 22272ms;
}
.stars-axis-182 {
  animation: anim180 15967ms, fade1 15967ms;
}
.stars-axis-183 {
  animation: anim180 44666ms, fade3 44666ms;
}
.stars-axis-184 {
  animation: anim184 44416ms, fade10 44416ms;
}
.stars-axis-185 {
  animation: anim184 37567ms, fade8 37567ms;
}
.stars-axis-186 {
  animation: anim184 15272ms, fade3 15272ms;
}
.stars-axis-187 {
  animation: anim184 29272ms, fade8 29272ms;
}
.stars-axis-188 {
  animation: anim188 7745ms, fade5 7745ms;
}
.stars-axis-189 {
  animation: anim188 27949ms, fade8 27949ms;
}
.stars-axis-190 {
  animation: anim188 34722ms, fade4 34722ms;
}
.stars-axis-191 {
  animation: anim188 44819ms, fade5 44819ms;
}
.stars-axis-192 {
  animation: anim192 21445ms, fade1 21445ms;
}
.stars-axis-193 {
  animation: anim192 34889ms, fade2 34889ms;
}
.stars-axis-194 {
  animation: anim192 37474ms, fade5 37474ms;
}
.stars-axis-195 {
  animation: anim192 31336ms, fade2 31336ms;
}
.stars-axis-196 {
  animation: anim196 10278ms, fade9 10278ms;
}
.stars-axis-197 {
  animation: anim196 9807ms, fade1 9807ms;
}
.stars-axis-198 {
  animation: anim196 5047ms, fade6 5047ms;
}
.stars-axis-199 {
  animation: anim196 12168ms, fade7 12168ms;
}
.stars-axis-200 {
  animation: anim200 38982ms, fade6 38982ms;
}
.stars-axis-201 {
  animation: anim200 32225ms, fade6 32225ms;
}
.stars-axis-202 {
  animation: anim200 30556ms, fade3 30556ms;
}
.stars-axis-203 {
  animation: anim200 34196ms, fade8 34196ms;
}
.stars-axis-204 {
  animation: anim204 37489ms, fade9 37489ms;
}
.stars-axis-205 {
  animation: anim204 12159ms, fade9 12159ms;
}
.stars-axis-206 {
  animation: anim204 7517ms, fade9 7517ms;
}
.stars-axis-207 {
  animation: anim204 6459ms, fade9 6459ms;
}
.stars-axis-208 {
  animation: anim208 29754ms, fade4 29754ms;
}
.stars-axis-209 {
  animation: anim208 14634ms, fade9 14634ms;
}
.stars-axis-210 {
  animation: anim208 37752ms, fade3 37752ms;
}
.stars-axis-211 {
  animation: anim208 22392ms, fade8 22392ms;
}
.stars-axis-212 {
  animation: anim212 11057ms, fade7 11057ms;
}
.stars-axis-213 {
  animation: anim212 6799ms, fade4 6799ms;
}
.stars-axis-214 {
  animation: anim212 37859ms, fade6 37859ms;
}
.stars-axis-215 {
  animation: anim212 28510ms, fade4 28510ms;
}
.stars-axis-216 {
  animation: anim216 17838ms, fade7 17838ms;
}
.stars-axis-217 {
  animation: anim216 7139ms, fade10 7139ms;
}
.stars-axis-218 {
  animation: anim216 14772ms, fade6 14772ms;
}
.stars-axis-219 {
  animation: anim216 15071ms, fade8 15071ms;
}
.stars-axis-220 {
  animation: anim220 23703ms, fade8 23703ms;
}
.stars-axis-221 {
  animation: anim220 28672ms, fade4 28672ms;
}
.stars-axis-222 {
  animation: anim220 19111ms, fade8 19111ms;
}
.stars-axis-223 {
  animation: anim220 24331ms, fade9 24331ms;
}
.stars-axis-224 {
  animation: anim224 19502ms, fade10 19502ms;
}
.stars-axis-225 {
  animation: anim224 32237ms, fade6 32237ms;
}
.stars-axis-226 {
  animation: anim224 29291ms, fade2 29291ms;
}
.stars-axis-227 {
  animation: anim224 16610ms, fade1 16610ms;
}
.stars-axis-228 {
  animation: anim228 15888ms, fade8 15888ms;
}
.stars-axis-229 {
  animation: anim228 15308ms, fade5 15308ms;
}
.stars-axis-230 {
  animation: anim228 10455ms, fade4 10455ms;
}
.stars-axis-231 {
  animation: anim228 9000ms, fade9 9000ms;
}
.stars-axis-232 {
  animation: anim232 23461ms, fade9 23461ms;
}
.stars-axis-233 {
  animation: anim232 33838ms, fade10 33838ms;
}
.stars-axis-234 {
  animation: anim232 30123ms, fade2 30123ms;
}
.stars-axis-235 {
  animation: anim232 25170ms, fade2 25170ms;
}
.stars-axis-236 {
  animation: anim236 16490ms, fade8 16490ms;
}
.stars-axis-237 {
  animation: anim236 35917ms, fade1 35917ms;
}
.stars-axis-238 {
  animation: anim236 32682ms, fade3 32682ms;
}
.stars-axis-239 {
  animation: anim236 29145ms, fade2 29145ms;
}
.stars-axis-240 {
  animation: anim240 30042ms, fade9 30042ms;
}
.stars-axis-241 {
  animation: anim240 7168ms, fade1 7168ms;
}
.stars-axis-242 {
  animation: anim240 22249ms, fade2 22249ms;
}
.stars-axis-243 {
  animation: anim240 39268ms, fade3 39268ms;
}
.stars-axis-244 {
  animation: anim244 12513ms, fade5 12513ms;
}
.stars-axis-245 {
  animation: anim244 21479ms, fade1 21479ms;
}
.stars-axis-246 {
  animation: anim244 9665ms, fade2 9665ms;
}
.stars-axis-247 {
  animation: anim244 32753ms, fade3 32753ms;
}
.stars-axis-248 {
  animation: anim248 25210ms, fade1 25210ms;
}
.stars-axis-249 {
  animation: anim248 31147ms, fade8 31147ms;
}
.stars-axis-250 {
  animation: anim248 37493ms, fade8 37493ms;
}
.stars-axis-251 {
  animation: anim248 27619ms, fade3 27619ms;
}
.stars-axis-252 {
  animation: anim252 26735ms, fade2 26735ms;
}
.stars-axis-253 {
  animation: anim252 15300ms, fade7 15300ms;
}
.stars-axis-254 {
  animation: anim252 6569ms, fade2 6569ms;
}
.stars-axis-255 {
  animation: anim252 16192ms, fade8 16192ms;
}
.stars-axis-256 {
  animation: anim256 28503ms, fade5 28503ms;
}
.stars-axis-257 {
  animation: anim256 34890ms, fade7 34890ms;
}
.stars-axis-258 {
  animation: anim256 33569ms, fade7 33569ms;
}
.stars-axis-259 {
  animation: anim256 32179ms, fade2 32179ms;
}
.stars-axis-260 {
  animation: anim260 16385ms, fade4 16385ms;
}
.stars-axis-261 {
  animation: anim260 35903ms, fade1 35903ms;
}
.stars-axis-262 {
  animation: anim260 5787ms, fade6 5787ms;
}
.stars-axis-263 {
  animation: anim260 38199ms, fade2 38199ms;
}
.stars-axis-264 {
  animation: anim264 12763ms, fade5 12763ms;
}
.stars-axis-265 {
  animation: anim264 40898ms, fade6 40898ms;
}
.stars-axis-266 {
  animation: anim264 33562ms, fade5 33562ms;
}
.stars-axis-267 {
  animation: anim264 36427ms, fade5 36427ms;
}
.stars-axis-268 {
  animation: anim268 39984ms, fade8 39984ms;
}
.stars-axis-269 {
  animation: anim268 25238ms, fade9 25238ms;
}
.stars-axis-270 {
  animation: anim268 44089ms, fade10 44089ms;
}
.stars-axis-271 {
  animation: anim268 35230ms, fade3 35230ms;
}
.stars-axis-272 {
  animation: anim272 39459ms, fade1 39459ms;
}
.stars-axis-273 {
  animation: anim272 33535ms, fade1 33535ms;
}
.stars-axis-274 {
  animation: anim272 17701ms, fade5 17701ms;
}
.stars-axis-275 {
  animation: anim272 8055ms, fade8 8055ms;
}
.stars-axis-276 {
  animation: anim276 29929ms, fade2 29929ms;
}
.stars-axis-277 {
  animation: anim276 35407ms, fade9 35407ms;
}
.stars-axis-278 {
  animation: anim276 15348ms, fade2 15348ms;
}
.stars-axis-279 {
  animation: anim276 34002ms, fade10 34002ms;
}
.stars-axis-280 {
  animation: anim280 8408ms, fade4 8408ms;
}
.stars-axis-281 {
  animation: anim280 6621ms, fade5 6621ms;
}
.stars-axis-282 {
  animation: anim280 24910ms, fade1 24910ms;
}
.stars-axis-283 {
  animation: anim280 28986ms, fade1 28986ms;
}
.stars-axis-284 {
  animation: anim284 43036ms, fade6 43036ms;
}
.stars-axis-285 {
  animation: anim284 20459ms, fade9 20459ms;
}
.stars-axis-286 {
  animation: anim284 12392ms, fade6 12392ms;
}
.stars-axis-287 {
  animation: anim284 30281ms, fade6 30281ms;
}
.stars-axis-288 {
  animation: anim288 35230ms, fade8 35230ms;
}
.stars-axis-289 {
  animation: anim288 32665ms, fade10 32665ms;
}
.stars-axis-290 {
  animation: anim288 24952ms, fade8 24952ms;
}
.stars-axis-291 {
  animation: anim288 22751ms, fade5 22751ms;
}
.stars-axis-292 {
  animation: anim292 19652ms, fade8 19652ms;
}
.stars-axis-293 {
  animation: anim292 6393ms, fade5 6393ms;
}
.stars-axis-294 {
  animation: anim292 23599ms, fade8 23599ms;
}
.stars-axis-295 {
  animation: anim292 29006ms, fade1 29006ms;
}
.stars-axis-296 {
  animation: anim296 24100ms, fade5 24100ms;
}
.stars-axis-297 {
  animation: anim296 8308ms, fade8 8308ms;
}
.stars-axis-298 {
  animation: anim296 19017ms, fade10 19017ms;
}
.stars-axis-299 {
  animation: anim296 9375ms, fade2 9375ms;
}
.stars-axis-300 {
  animation: anim300 11143ms, fade3 11143ms;
}
.stars-axis-301 {
  animation: anim300 11558ms, fade1 11558ms;
}
.stars-axis-302 {
  animation: anim300 16609ms, fade3 16609ms;
}
.stars-axis-303 {
  animation: anim300 5352ms, fade10 5352ms;
}
.stars-axis-304 {
  animation: anim304 9064ms, fade7 9064ms;
}
.stars-axis-305 {
  animation: anim304 31992ms, fade1 31992ms;
}
.stars-axis-306 {
  animation: anim304 29751ms, fade10 29751ms;
}
.stars-axis-307 {
  animation: anim304 33971ms, fade8 33971ms;
}
.stars-axis-308 {
  animation: anim308 19010ms, fade4 19010ms;
}
.stars-axis-309 {
  animation: anim308 23662ms, fade3 23662ms;
}
.stars-axis-310 {
  animation: anim308 31281ms, fade3 31281ms;
}
.stars-axis-311 {
  animation: anim308 24244ms, fade5 24244ms;
}
.stars-axis-312 {
  animation: anim312 15669ms, fade10 15669ms;
}
.stars-axis-313 {
  animation: anim312 22229ms, fade9 22229ms;
}
.stars-axis-314 {
  animation: anim312 41696ms, fade3 41696ms;
}
.stars-axis-315 {
  animation: anim312 12399ms, fade7 12399ms;
}
.stars-axis-316 {
  animation: anim316 19356ms, fade3 19356ms;
}
.stars-axis-317 {
  animation: anim316 26385ms, fade7 26385ms;
}
.stars-axis-318 {
  animation: anim316 14626ms, fade4 14626ms;
}
.stars-axis-319 {
  animation: anim316 28369ms, fade4 28369ms;
}
.stars-axis-320 {
  animation: anim320 31243ms, fade3 31243ms;
}
.stars-axis-321 {
  animation: anim320 39358ms, fade1 39358ms;
}
.stars-axis-322 {
  animation: anim320 18434ms, fade7 18434ms;
}
.stars-axis-323 {
  animation: anim320 32596ms, fade4 32596ms;
}
.stars-axis-324 {
  animation: anim324 43448ms, fade2 43448ms;
}
.stars-axis-325 {
  animation: anim324 25867ms, fade9 25867ms;
}
.stars-axis-326 {
  animation: anim324 22748ms, fade3 22748ms;
}
.stars-axis-327 {
  animation: anim324 10897ms, fade4 10897ms;
}
.stars-axis-328 {
  animation: anim328 9566ms, fade8 9566ms;
}
.stars-axis-329 {
  animation: anim328 28859ms, fade9 28859ms;
}
.stars-axis-330 {
  animation: anim328 30081ms, fade1 30081ms;
}
.stars-axis-331 {
  animation: anim328 21203ms, fade2 21203ms;
}
.stars-axis-332 {
  animation: anim332 44715ms, fade1 44715ms;
}
.stars-axis-333 {
  animation: anim332 10755ms, fade2 10755ms;
}
.stars-axis-334 {
  animation: anim332 38528ms, fade4 38528ms;
}
.stars-axis-335 {
  animation: anim332 9000ms, fade6 9000ms;
}
.stars-axis-336 {
  animation: anim336 38416ms, fade6 38416ms;
}
.stars-axis-337 {
  animation: anim336 37534ms, fade8 37534ms;
}
.stars-axis-338 {
  animation: anim336 32940ms, fade7 32940ms;
}
.stars-axis-339 {
  animation: anim336 29635ms, fade1 29635ms;
}
.stars-axis-340 {
  animation: anim340 40559ms, fade9 40559ms;
}
.stars-axis-341 {
  animation: anim340 15890ms, fade7 15890ms;
}
.stars-axis-342 {
  animation: anim340 29627ms, fade8 29627ms;
}
.stars-axis-343 {
  animation: anim340 17153ms, fade7 17153ms;
}
.stars-axis-344 {
  animation: anim344 32013ms, fade10 32013ms;
}
.stars-axis-345 {
  animation: anim344 44858ms, fade4 44858ms;
}
.stars-axis-346 {
  animation: anim344 25031ms, fade5 25031ms;
}
.stars-axis-347 {
  animation: anim344 38209ms, fade2 38209ms;
}
.stars-axis-348 {
  animation: anim348 12231ms, fade7 12231ms;
}
.stars-axis-349 {
  animation: anim348 43072ms, fade1 43072ms;
}
.stars-axis-350 {
  animation: anim348 12547ms, fade4 12547ms;
}
.stars-axis-351 {
  animation: anim348 27151ms, fade3 27151ms;
}
.stars-axis-352 {
  animation: anim352 11805ms, fade6 11805ms;
}
.stars-axis-353 {
  animation: anim352 29502ms, fade10 29502ms;
}
.stars-axis-354 {
  animation: anim352 38090ms, fade5 38090ms;
}
.stars-axis-355 {
  animation: anim352 44422ms, fade7 44422ms;
}
.stars-axis-356 {
  animation: anim356 26092ms, fade5 26092ms;
}
.stars-axis-357 {
  animation: anim356 20815ms, fade5 20815ms;
}
.stars-axis-358 {
  animation: anim356 20640ms, fade1 20640ms;
}
.stars-axis-359 {
  animation: anim356 22017ms, fade7 22017ms;
}
@keyframes fade1 {
  7% {
    opacity: 0;
  }
  17% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade2 {
  17% {
    opacity: 0;
  }
  27% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade3 {
  5% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade4 {
  9% {
    opacity: 0;
  }
  19% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade5 {
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade6 {
  19% {
    opacity: 0;
  }
  29% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade7 {
  9% {
    opacity: 0;
  }
  19% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade8 {
  7% {
    opacity: 0;
  }
  17% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade9 {
  5% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade10 {
  14% {
    opacity: 0;
  }
  24% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes anim4 {
  100% {
    transform: translate(79.8051240208vw, 5.5805178995vh);
  }
}
@keyframes anim8 {
  100% {
    transform: translate(79.2214454993vw, 11.1338480768vh);
  }
}
@keyframes anim12 {
  100% {
    transform: translate(78.2518080587vw, 16.6329352654vh);
  }
}
@keyframes anim16 {
  100% {
    transform: translate(76.9009356751vw, 22.0509884654vh);
  }
}
@keyframes anim20 {
  100% {
    transform: translate(75.1754096629vw, 27.3616114661vh);
  }
}
@keyframes anim24 {
  100% {
    transform: translate(73.0836366114vw, 32.5389314461vh);
  }
}
@keyframes anim28 {
  100% {
    transform: translate(70.6358074287vw, 37.5577250229vh);
  }
}
@keyframes anim32 {
  100% {
    transform: translate(67.8438476925vw, 42.3935411387vh);
  }
}
@keyframes anim36 {
  100% {
    transform: translate(64.72135955vw, 47.0228201834vh);
  }
}
@keyframes anim40 {
  100% {
    transform: translate(61.2835554495vw, 51.4230087749vh);
  }
}
@keyframes anim44 {
  100% {
    transform: translate(57.5471840271vw, 55.5726696367vh);
  }
}
@keyframes anim48 {
  100% {
    transform: translate(53.5304485087vw, 59.4515860382vh);
  }
}
@keyframes anim52 {
  100% {
    transform: translate(49.252918026vw, 63.0408602885vh);
  }
}
@keyframes anim56 {
  100% {
    transform: translate(44.7354322777vw, 66.3230058044vh);
  }
}
@keyframes anim60 {
  100% {
    transform: translate(40vw, 69.2820323028vh);
  }
}
@keyframes anim64 {
  100% {
    transform: translate(35.0696917431vw, 71.9035237039vh);
  }
}
@keyframes anim68 {
  100% {
    transform: translate(29.9685274733vw, 74.1747083653vh);
  }
}
@keyframes anim72 {
  100% {
    transform: translate(24.72135955vw, 76.0845213036vh);
  }
}
@keyframes anim76 {
  100% {
    transform: translate(19.353751648vw, 77.6236581021vh);
  }
}
@keyframes anim80 {
  100% {
    transform: translate(13.8918542133vw, 78.784620241vh);
  }
}
@keyframes anim84 {
  100% {
    transform: translate(8.3622770614vw, 79.5617516295vh);
  }
}
@keyframes anim88 {
  100% {
    transform: translate(2.7919597362vw, 79.9512661615vh);
  }
}
@keyframes anim92 {
  100% {
    transform: translate(-2.7919597362vw, 79.9512661615vh);
  }
}
@keyframes anim96 {
  100% {
    transform: translate(-8.3622770614vw, 79.5617516295vh);
  }
}
@keyframes anim100 {
  100% {
    transform: translate(-13.8918542134vw, 78.784620241vh);
  }
}
@keyframes anim104 {
  100% {
    transform: translate(-19.353751648vw, 77.6236581021vh);
  }
}
@keyframes anim108 {
  100% {
    transform: translate(-24.72135955vw, 76.0845213036vh);
  }
}
@keyframes anim112 {
  100% {
    transform: translate(-29.9685274733vw, 74.1747083653vh);
  }
}
@keyframes anim116 {
  100% {
    transform: translate(-35.0696917431vw, 71.9035237039vh);
  }
}
@keyframes anim120 {
  100% {
    transform: translate(-40vw, 69.2820323027vh);
  }
}
@keyframes anim124 {
  100% {
    transform: translate(-44.7354322777vw, 66.3230058044vh);
  }
}
@keyframes anim128 {
  100% {
    transform: translate(-49.2529180261vw, 63.0408602885vh);
  }
}
@keyframes anim132 {
  100% {
    transform: translate(-53.5304485087vw, 59.4515860382vh);
  }
}
@keyframes anim136 {
  100% {
    transform: translate(-57.5471840271vw, 55.5726696367vh);
  }
}
@keyframes anim140 {
  100% {
    transform: translate(-61.2835554495vw, 51.4230087749vh);
  }
}
@keyframes anim144 {
  100% {
    transform: translate(-64.72135955vw, 47.0228201834vh);
  }
}
@keyframes anim148 {
  100% {
    transform: translate(-67.8438476924vw, 42.3935411387vh);
  }
}
@keyframes anim152 {
  100% {
    transform: translate(-70.6358074286vw, 37.5577250229vh);
  }
}
@keyframes anim156 {
  100% {
    transform: translate(-73.0836366112vw, 32.5389314461vh);
  }
}
@keyframes anim160 {
  100% {
    transform: translate(-75.1754096624vw, 27.3616114661vh);
  }
}
@keyframes anim164 {
  100% {
    transform: translate(-76.9009356743vw, 22.0509884654vh);
  }
}
@keyframes anim168 {
  100% {
    transform: translate(-78.2518080574vw, 16.6329352656vh);
  }
}
@keyframes anim172 {
  100% {
    transform: translate(-79.2214454971vw, 11.1338480771vh);
  }
}
@keyframes anim176 {
  100% {
    transform: translate(-79.8051240171vw, 5.5805179vh);
  }
}
@keyframes anim180 {
  100% {
    transform: translate(-79.9999999939vw, 0.0000000008vh);
  }
}
@keyframes anim184 {
  100% {
    transform: translate(-79.805124011vw, -5.5805178982vh);
  }
}
@keyframes anim188 {
  100% {
    transform: translate(-79.2214454836vw, -11.1338480746vh);
  }
}
@keyframes anim192 {
  100% {
    transform: translate(-78.2518080337vw, -16.6329352618vh);
  }
}
@keyframes anim196 {
  100% {
    transform: translate(-76.9009356358vw, -22.0509884595vh);
  }
}
@keyframes anim200 {
  100% {
    transform: translate(-75.1754096017vw, -27.3616114568vh);
  }
}
@keyframes anim204 {
  100% {
    transform: translate(-73.0836365169vw, -32.5389314314vh);
  }
}
@keyframes anim208 {
  100% {
    transform: translate(-70.6358072839vw, -37.557725vh);
  }
}
@keyframes anim212 {
  100% {
    transform: translate(-67.8438474725vw, -42.3935411032vh);
  }
}
@keyframes anim216 {
  100% {
    transform: translate(-64.7213592185vw, -47.022820129vh);
  }
}
@keyframes anim220 {
  100% {
    transform: translate(-61.2835549536vw, -51.423008692vh);
  }
}
@keyframes anim224 {
  100% {
    transform: translate(-57.5471832906vw, -55.5726695113vh);
  }
}
@keyframes anim228 {
  100% {
    transform: translate(-53.5304474226vw, -59.4515858499vh);
  }
}
@keyframes anim232 {
  100% {
    transform: translate(-49.2529164353vw, -63.0408600078vh);
  }
}
@keyframes anim236 {
  100% {
    transform: translate(-44.735429963vw, -66.3230053889vh);
  }
}
@keyframes anim240 {
  100% {
    transform: translate(-39.9999966533vw, -69.2820316917vh);
  }
}
@keyframes anim244 {
  100% {
    transform: translate(-35.0696869337vw, -71.9035228111vh);
  }
}
@keyframes anim248 {
  100% {
    transform: translate(-29.9685206026vw, -74.1747070689vh);
  }
}
@keyframes anim252 {
  100% {
    transform: translate(-24.721349791vw, -76.0845194323vh);
  }
}
@keyframes anim256 {
  100% {
    transform: translate(-19.3537378633vw, -77.6236554167vh);
  }
}
@keyframes anim260 {
  100% {
    transform: translate(-13.891834847vw, -78.7846164089vh);
  }
}
@keyframes anim264 {
  100% {
    transform: translate(-8.362249995vw, -79.5617461908vh);
  }
}
@keyframes anim268 {
  100% {
    transform: translate(-2.7919220994vw, -79.9512584836vh);
  }
}
@keyframes anim272 {
  100% {
    transform: translate(2.7920118149vw, -79.951255378vh);
  }
}
@keyframes anim276 {
  100% {
    transform: translate(8.3623487803vw, -79.5617365594vh);
  }
}
@keyframes anim280 {
  100% {
    transform: translate(13.8919525223vw, -78.7845992823vh);
  }
}
@keyframes anim284 {
  100% {
    transform: translate(19.3538857994vw, -77.6236290907vh);
  }
}
@keyframes anim288 {
  100% {
    transform: translate(24.7215418116vw, -76.0844813291vh);
  }
}
@keyframes anim292 {
  100% {
    transform: translate(29.9687740459vw, -74.1746535294vh);
  }
}
@keyframes anim296 {
  100% {
    transform: translate(35.0700239391vw, -71.9034488065vh);
  }
}
@keyframes anim300 {
  100% {
    transform: translate(40.0004457508vw, -69.2819304349vh);
  }
}
@keyframes anim304 {
  100% {
    transform: translate(44.7360280553vw, -66.3228678216vh);
  }
}
@keyframes anim308 {
  100% {
    transform: translate(49.2537112853vw, -63.0406741325vh);
  }
}
@keyframes anim312 {
  100% {
    transform: translate(53.5315007796vw, -59.4513358667vh);
  }
}
@keyframes anim316 {
  100% {
    transform: translate(57.5485748204vw, -55.5723347095vh);
  }
}
@keyframes anim320 {
  100% {
    transform: translate(61.2853871724vw, -51.4225620336vh);
  }
}
@keyframes anim324 {
  100% {
    transform: translate(64.7237636756vw, -47.0222264458vh);
  }
}
@keyframes anim328 {
  100% {
    transform: translate(67.8469924807vw, -42.39275481vh);
  }
}
@keyframes anim332 {
  100% {
    transform: translate(70.6399075605vw, -37.5566872034vh);
  }
}
@keyframes anim336 {
  100% {
    transform: translate(73.0889651791vw, -32.5375662873vh);
  }
}
@keyframes anim340 {
  100% {
    transform: translate(75.1823130503vw, -27.3598215917vh);
  }
}
@keyframes anim344 {
  100% {
    transform: translate(76.9098519779vw, -22.0486492345vh);
  }
}
@keyframes anim348 {
  100% {
    transform: translate(78.2632898269vw, -16.6298876035vh);
  }
}
@keyframes anim352 {
  100% {
    transform: translate(79.2361877464vw, -11.1298895415vh);
  }
}
@keyframes anim356 {
  100% {
    transform: translate(79.8239986358vw, -5.5753915744vh);
  }
}
@keyframes anim360 {
  100% {
    transform: translate(80.0240979233vw, 0.0066192762vh);
  }
}
