
.otp-page-container {
  background-color: rgba(32, 33, 32, 0.5);
  border-radius: 1rem;
  margin: 4rem 10%;
  padding-top: 1.25rem;
}
.otp-page-heading {
  font-size: 4rem;
  font-family: "Signika Negative", sans-serif;
  text-align: center;
  margin: 1rem 0;
}
.description-block{
    display: flex;
    margin: 1rem 2rem;
    align-items: center;
}
.description-block div{
    margin: 0.2rem 1rem;
    font-size: 1.1rem;
}
/* .description-block div  a{
    text-decoration: none;
} */
.inputf{
    background-color: white;
    border: 3px;
    border-color: black;
    border-radius: 10px;
    color: black;
    width: max-content;
    padding: 6px;
}
#disemail{
    background-color: rgb(187, 186, 186);
}

.description-block div input:focus{
    border: 2px solid red;
    border-radius: 5px;
}
#submitit{
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: center;
}
#submitit input{
    cursor: pointer;
    font-size: 1.3rem;
    padding: 0.2rem 1rem;
}
.outer-otp-page{
    display: flex;
    align-items: center;
    justify-content: center;
}
.description-block div input:focus{
    border: 0px
}

#otp{
    padding-left: 3px;
}

.description-block div input::-webkit-outer-spin-button,
.description-block div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.description-block div input[type=number] {
  -moz-appearance: textfield;
}
.otp-submit{
    padding: 0.2rem 1.3rem ;
    border: 3px solid rgb(138, 70, 239);
    border-radius: 5px;
    background-color: rgba(32, 33, 32, 0.5);
    color: rgb(138, 70, 239);
    transition: all ;
    transition-duration: 0.15s;
    transition-timing-function: linear;
}
.otp-submit:hover{
    background-color: rgb(138, 70, 239);
    color: black;
}

#resend{
    width: 100%;
    margin-bottom: 2%;
}

#otp-time{
    display: none;
}

#resend-otp{
    border: none;
    background-color: transparent;
    color: white;
    font-size: inherit;
    text-decoration: underline;
}

@media only screen and (max-width:700px)  {
    .otp-page-heading {
        font-size: 2.3rem;
        padding: 0.5rem;
      }
    .description-block{
        font-size: 0.9rem;
        }
        /* .description-block div input{
            font-size: 0.9rem;
            width: 8rem;
        } */
    .description-block div{
            margin: 0.2rem 0.5rem;
        }    
    #otp-description{
        width: 70%;
    }
}
@media only screen and (max-width:1000px) and (min-width:700px) {
    .otp-page-heading {
        font-size: 3rem;
        padding: 0.5rem;
      }
    .description-block{
        font-size: 1.2rem;
        }
    .description-block div input{
            font-size: 1.2rem;
        }
    .description-block div{
            margin: 0.2rem 0.5rem;
        }
}
#resend-otp{
    cursor: pointer;
}
#resend-otp:hover{
    color: rgb(138, 70, 239);
}



