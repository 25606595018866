@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto+Slab&display=swap");

.form-wrap-about {
  display: flex;
  justify-content: center;
  margin: 2% 10% 3% 10%;
  align-items: center;
  background-color: rgba(33, 32, 32, 0.5);
  border-radius: 30px 30px 30px 30px;
  flex-direction: column;
}

.about_title {
  color: white;
  font-size: 30px;
  font-family: 'Roboto Mono', monospace;
  margin-top: 35px;
}

@media (max-width: 1100px) {
  .about_title {
    font-size: 20px;
  }
}

.p-about {
  color: white;
  font-size: 18px;
  font-family: "Roboto Slab", serif;
}

.text_area-about {
  text-align: justify;
  padding: 5% 10%;
}

.social-icons-about {
  list-style: none;
  text-align: center;
  margin: 20px 0px;
}
.social-icons-about li {
  display: inline-block;
}
.social-icons-about li i {
  background: #23181a;
  color: #fff;
  padding: 15px;
  font-size: 20px;
  border-radius: 22%;
  margin: 0px 7px;
  cursor: pointer;
  transition: all 0.2s;
}
.social-icons-about li i:hover {
  background: #03e9f4;
  color: #000000;
}
