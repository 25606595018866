profile-h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5em;
  padding: 0.2em;
}

/*body::before {
  content: "";
  background-image: url("https://images.unsplash.com/photo-1528818955841-a7f1425131b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
  opacity: 0.8;
  position: absolute;
  inset: 0;
}*/

.profile-parent {
  width: 70%;
  margin: 3em 15%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.354);
  padding: 0.5em 0em;
  border: 1px solid white;

  /* background: rgb(97, 3, 109); */
  /* background: radial-gradient(circle, rgba(97, 3, 109, 1) 0%, rgba(23, 4, 91, 1) 89%, rgba(14, 2, 58, 1) 100%); */
}

.profile-container1 {
  display: flex;
  font-family: "Times New Roman", Times, serif;
  background: rgb(63, 2, 71);
  background: radial-gradient(
    circle,
    rgba(63, 2, 71, 1) 0%,
    rgba(14, 2, 56, 1) 89%,
    rgba(14, 2, 58, 1) 100%
  );
  margin: 1em;
  font-size: 1.1em;
}

.profile-container2 {
  display: flex;
  flex-direction: column;
  font-family: "Times New Roman", Times, serif;
  align-items: center;
  justify-content: center;
  outline: 1px solid rgb(0, 0, 0);
  padding: 1em 2em;
  font-size: 1.3em;
  /* background-color: rgba(0, 0, 0, 0.354); */
  /* background-color: rgba(16, 5, 40, 0.5); */
  background: rgb(63, 2, 71);
  background: radial-gradient(
    circle,
    rgba(63, 2, 71, 1) 0%,
    rgba(14, 2, 56, 1) 89%,
    rgba(14, 2, 58, 1) 100%
  );
  margin: 1em;
}

.profile-container2 div {
  padding: 0.5em;
  font-weight: bold;
}

.personal_info {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* outline:1px solid white; */
  padding: 0em 1em;
  line-height: 2em;
  font-size: 1.5em;
  font-weight: bold;
}

.personal_info > div{
  font-size: 1.2em;
}

.profile-gravatar {
  padding: 1em;
  /* outline:1px solid white; */
}
.profile-image {
  width: 12rem;
}

.fest-payment{
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65vw;
}


/* .profile-information{
  outline:1px solid white;

} */

/* scrollbar styling */
.profile-information::-webkit-scrollbar {
  /* display: none; */
  background-color: rgb(236, 122, 0);
  width: 10px;
}

.profile-information::-webkit-scrollbar-thumb {
  background-color: rgb(139, 0, 253);
  border-radius: 20px;
}

.otp-submit{
  height: 7vh;
  border: 3px solid rgb(138, 70, 239);
  border-radius: 5px;
  background-color: rgba(32, 33, 32, 0.5);
  color: rgb(138, 70, 239);
  cursor: pointer;
  transition: all ;
  transition-duration: 0.15s;
  transition-timing-function: linear;
}
.otp-submit:hover{
  background-color: rgb(138, 70, 239);
  color: black;
}

.notices{
  font-size: 1.2rem;
}

/* .personal_info > div {
  display: flex;
  flex-direction: column;
} */

@media (max-width: 1430px) {
  .profile-parent {
    width: 90%;
    margin: 3em 5%;
  }
}

@media (max-width: 950px) {
  .profile-container1 {
    flex-direction: column;
    margin: 1rem;
    padding: 1em 2em;
    align-items: center;
  }
  .personal_info {
    font-size: 1.3rem;
  }
  .profile-image {
    width: 10rem;
  }
  .notices{
    font-size: 1.1rem;
  }
}

@media (max-width: 500px) {
  .personal_info {
    font-size: 0.8rem;
  }
  .profile-information {
    width: 125%;
    font-size: 0.9rem;
  }
  .notices{
    font-size: 0.9rem;
  }
}
/* teams page styling 

.event-name {
  text-align: center;
  padding: 2em;
}
.team-members {
  display: flex;
  justify-content: space-evenly;
}
.name > p {
  padding: 1em;
}
.members-id > p {
  padding: 1em;
}*/
