@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Roboto:wght@300;400;500;700&display=swap");

.login-part {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
}
.login-page-container {
  /* height: 100%;
	 */
  position: relative;
  z-index: 10;
}
.cloud2 {
  width: 300px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.9;
}
.cloud3 {
  width: 300px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.9;
}
.login-page {
  margin: auto;
  /* height: 600px;
	 */
  width: 35%;
  display: flex;
  /* flex-wrap: wrap;
	 */
  /* margin-top: 40px;
	 */
  /* margin-bottom: 50px;
	 */
  /* padding-bottom: 20px;
	 */
  padding: 50px 0;
  /* box-shadow: 0 10px 20px rgb(92, 60, 133);
	 */
  /* border-radius: 50px;
	 */
}
.back {
  position: absolute;
  top: 1px;
  left: 20px;
  background-color: white;
  /* background-color: #41287c;
	 */
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #703f90;
  border: none;
  outline: none;
  cursor: pointer;
}
.back:hover {
  background-color: #9c2388;
  color: white;
}
.back i {
  position: relative;
  left: -1px;
}
.login-detail {
  background-color: white;
  /* width: 35%;
	 */
  flex: 7;
  padding-bottom: 80px;
  /* border-top-left-radius: 20px;
	 */
  /* height: 100%;
	 */
}
.login-detail img {
  width: 65%;
}
.login-detail .login {
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap;
	 */
  /* align-items: center;
	 */
  /* justify-content: start;
	 */
  padding: 0 15%;
}
.login-detail .login-info {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap;
	 */
  color: #472b7f;
  margin-top: 60px;
  /* align-items: center;
	 */
}
.login-detail form {
  display: flex;
  flex-direction: column;
}
.login-detail h1 {
  color: #472b7f;
  font-weight: 400;
}
.login-detail h2 {
  color: #472b7f;
  font-weight: 400;
  margin-bottom: 20px;
}
.login-detail .input {
  /* color: #472b7f;
	 */
  border-bottom: 1.5px solid #bdbcc2;
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 5px;
  margin: 10px;
}
.login-detail i {
  margin-right: 5px;
}
.login-detail input {
  outline: none;
  border: none;
  color: #472b7f;
  font-size: 18px;
  width: 90%;
  /* font-weight: 400;
	 */
}
.login-detail input::placeholder {
  color: #472b7f;
  font-weight: 400;
}
.login-detail button {
  /* margin-top: 40px;
	 */
  background-color: transparent;
  background: linear-gradient(90deg, #704191, #4c2f81);
  border: none;
  outline: none;
  color: white;
  font-size: 20px;
  padding: 15px 0;
  border-radius: 7px;
  font-weight: 500;
  margin: 30px 0 10px;
  cursor: pointer;
}
.login-detail button:hover {
  background: linear-gradient(90deg, #9e1092, #8817b4);
}
.login-detail .new {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.login-detail .new input {
  margin-right: 5px;
}
.login-detail .remember-me {
  display: flex;
  align-items: center;
}
.login-detail .signin {
  display: flex;
  font-weight: 500;
}
.login-detail .signin a {
  text-decoration: none;
}
.login-detail .signin .newhere {
  color: #9b8abd;
  margin-right: 5px;
}
.login-img {
  background-color: rgba(0, 0, 0, 0.2);
  /* width: 65%;
	 */
  flex: 13;
  /* height: 100%;
	 */
  /* align-self: stretch;
	 display: flex;
	 */
}
.login-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* flex-wrap: wrap;
	 */
  align-items: center;
  height: 100%;
}
.login-logo img {
  width: 50%;
  /* animation: planets 10s ease infinite ;
	 */
}
@keyframes planets {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.login-logo h1 {
  color: white;
  font-family: "Special Elite", cursive;
  line-height: 1.5;
  font-size: 45px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.login-logo h2 {
  color: white;
  font-family: "Special Elite", cursive;
  letter-spacing: 2px;
  line-height: 1.5;
  text-align: center;
}
@media (max-width: 1200px) {
  .login-page {
    width: 50%;
  }
}
@media (max-width: 900px) {
  .login-page {
    width: 80%;
  }
}
@media (max-width: 630px) {
  .login-page {
    width: 100%;
    /* margin: 20px 10px;
		 */
    flex-direction: column-reverse;
    /* flex-wrap: wrap;
		 */
    /* padding: 10px;
		 */
    align-items: stretch;
    justify-content: center;
    margin: 0;
    height: auto;
    box-shadow: 0 10px 20px #5c3c85;
  }
  .login-detail {
    /* width: 100%;
		 */
    /* align-self: stretch;
		 */
    width: auto;
    /* margin-bottom: 80px;
		 */
    margin: 0 10px 80px;
    flex: 1;
  }
  .login-detail .login {
    /* padding: 0;
		 */
    padding: 20px 20px 0;
  }
  .login-detail .input {
    margin: 10px 0;
  }
  .login-img {
    flex: 1;
    background: none;
    /* width: 100%;
		 */
    /* align-self: ;
		 */
    /* width: auto;
		 */
    /* align-items: center;
		 */
    /* width: fit-content;
		 */
    /* width: 100%;
		 */
    /* flex: 1;
		 */
    /* align-self: stretch;
		 */
  }
  .login-logo {
    /* height: auto;
		 */
    /* height: fit-content;
		 */
    padding: 50px 0 40px;
    /* margin-top: 80px;
		 */
    margin: 30px 10px 20px;
    /* justify-content: space-evenly;
		 */
  }
  .login-img img {
    width: 80%;
    /* height: auto;
		 */
    margin: 20px 0;
  }
  .login-img h1 {
    font-size: 35px;
    letter-spacing: 2px;
  }
  .login-img h2 {
    font-size: 22px;
    letter-spacing: 2px;
    line-height: 1.5;
  }
  .cloud2 {
    /* display: none;
		 */
    width: 160px;
  }
  .cloud3 {
    width: 160px;
    bottom: 0;
  }
}
