.heading-gallery {
  font-family: 'Roboto Mono', monospace;
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  padding: 2rem;
  position: relative;
  z-index: 0;
}

.container-gallery {
  position: relative;
  z-index: 10;
}

.container-gallery img {
  border-radius: 1rem;
  transition: 0.3s;
  margin: 5px 2px !important;
}

.container-gallery img:hover {
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
}
